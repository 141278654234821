// https://webpack.js.org/guides/dependency-management/#require-context
const requireServices = require.context('../services', false, /[\w-]+Service\.js$/)

export default (ctx, inject) => {
  requireServices.keys().forEach((fileName) => {
    const serviceExport = requireServices(fileName)
    const serviceName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')

    // Inject the service
    const serviceExportFunction = serviceExport.default || serviceExport
    inject(serviceName, serviceExportFunction(ctx.$axios))
  })
}
