export default ($axios) => ({
  // Sessions
  initSession(headers) {
    return $axios.$get('/v1/live_video/sessions/new', headers)
  },
  createSession(body) {
    return $axios.$post('/v1/live_video/sessions', body)
  },
  fetchSession(sessionUuid) {
    return $axios.$get(`/v1/live_video/sessions/${sessionUuid}`)
  },

  // Contact Request
  cancelContactRequest(sessionUuid) {
    return $axios.$delete(`/v1/live_video/contact_requests/${sessionUuid}`)
  },
  checkSoonContactRequest(params) {
    return $axios.$get('/v1/live_video/soon_session', { params })
  },
  createContactRequest(body) {
    return $axios.$post('/v1/live_video/contact_requests', body)
  },
  getContactRequest(sessionUuid) {
    return $axios.$get(`/v1/live_video/contact_requests/${sessionUuid}`)
  },
  getContactRequests() {
    return $axios
      .$get('/v1/live_video/contact_requests/')
      .then((response) => response.map(JSON.parse))
  },
  updateContactRequest(sessionUuid, params) {
    return $axios.$get(`/v1/live_video/contact_requests/${sessionUuid}/edit`, { params })
  },

  // Calendar
  getCalendars(params) {
    return $axios.$get('/v1/live_video/calendars', { params })
  },
})
