<template>
  <CatalogPage
    :filters="filters"
    :makes="makes"
    :models-count="modelsCount"
    :models="models"
    :pages-count="pagesCount"
    :seo-content="seoContent"
    :vehicles-count="vehiclesCount"
    :vehicles-on-offer-count="vehiclesOnOfferCount"
    :vehicles="vehicles"
    category="km0"
    vehicle-type="car"
  />
</template>

<script>
// Dependencies
import { markRaw } from 'vue'

// Queries
import vehiclesCarsQuery from '@/queries/vehiclesCarsQuery'
import { km0CarsOnOffer } from '@/queries/catalogQuery'

// Helpers & mixins
import { getFiltersFromUrl, getSeoContent } from '@/helpers/catalog'

// Components
import CatalogPage from '@/components/catalog/Page.vue'

export default {
  name: 'PagesCarsKm0',
  layout: 'empty',
  components: {
    CatalogPage,
  },
  data() {
    return {
      filters: {},
      initialLoad: false,
      makes: [],
      models: [],
      modelsCount: 0,
      pagesCount: 0,
      seoContent: {},
      vehicles: undefined,
      vehiclesCount: 0,
      vehiclesOnOfferCount: 0,
    }
  },
  async validate({ app, error, route, store, redirect }) {
    try {
      const vehicleType = 'car'
      const category = 'Km0'

      await store.dispatch('catalog/checkCatalogOptions', { vehicleType, category })

      const { filters } = await getFiltersFromUrl(
        store,
        category,
        route.params,
        route.query,
        app.i18n,
        app.$activeCampaigns(),
      )

      let redirectionConfig

      if (filters.invalidMakeSlug) {
        redirectionConfig = {
          name: 'cars-km0',
        }
      } else if (filters.invalidModelSlug) {
        redirectionConfig = {
          name: 'cars-km0',
          params: { facet1: filters.makeSlug },
        }
      } else if (filters.invalidParam) {
        redirectionConfig = {}

        if (filters.makeSlug) {
          redirectionConfig.params.facet1 = filters.makeSlug
        }

        if (filters.modelSlug) {
          redirectionConfig.params.facet2 = filters.modelSlug
        }

        redirectionConfig.name = 'cars-km0'
      }

      if (redirectionConfig) {
        redirect(301, app.localePath(redirectionConfig))
      } else {
        return true
      }
    } catch (err) {
      app.$logger('Error validating data', err)

      error({
        statusCode: err.response?.status || 500,
        message: err.response?.body || err || 'Internal Server Error',
      })
    }
  },
  async asyncData({ app, error, route, store, $axios }) {
    try {
      const client = app.apolloProvider.defaultClient
      const activeCampaigns = app.$activeCampaigns()
      const useCases = (store.getters['catalog/getUseCases'] || []).filter((useCase) => {
        if (Object.keys(activeCampaigns).includes(useCase) && activeCampaigns[useCase] === false) {
          return false
        }

        return true
      })

      let vehiclesOnOfferCount = 0
      if (useCases.length) {
        const { data: onOfferVehiclesData } = await client.query({
          query: km0CarsOnOffer,
          variables: { useCasesIn: useCases },
        })
        vehiclesOnOfferCount = onOfferVehiclesData.vehicles.nodesCount
      }

      return {
        ...(await fetchData(client, app.i18n, route, store, $axios, app.$activeCampaigns())),
        vehiclesOnOfferCount,
      }
    } catch (err) {
      app.$logger('Error fetching data', err)

      error({
        statusCode: err.response?.status || 500,
        message: err.response?.body || 'Internal Server Error',
      })
    }
  },
  watch: {
    '$route.query': {
      async handler() {
        if (this.initialLoad) {
          const {
            filters,
            makes,
            models,
            modelsCount,
            pagesCount,
            seoContent,
            vehicles,
            vehiclesCount,
          } = await fetchData(
            this.$apollo,
            this.$i18n,
            this.$route,
            this.$store,
            this.$axios,
            this.$activeCampaigns(),
          )

          this.filters = filters
          this.makes = makes
          this.models = models
          this.modelsCount = modelsCount
          this.pagesCount = pagesCount
          this.seoContent = seoContent
          this.vehicles = vehicles
          this.vehiclesCount = vehiclesCount
        }

        this.initialLoad = true
      },
      immediate: true,
    },
  },
  async fetch() {
    await this.$store.dispatch('campaign/extractQueryParams', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({
      addSeoAttributes: {
        canonicalQueries: ['page'],
      },
    })
  },
}

const fetchData = async (client, i18n, route, store, axios, activeCampaigns) => {
  const vehicleType = 'car'
  const category = 'Km0'

  const makes = store.getters['catalog/getMakes'] || []

  const { filters, models } = await getFiltersFromUrl(
    store,
    category,
    route.params,
    route.query,
    i18n,
    activeCampaigns,
  )

  const variables = {
    financialEntityName: process.env.defaultFinancialEntity,
    language: store.getters['index/languageIso'] || 'es',
    ...filters,
  }

  const { vehicles, models: vehicleModels } = await client
    .query({
      query: markRaw(vehiclesCarsQuery),
      variables,
    })
    .then(({ data }) => data)

  const seoContent = await getSeoContent(vehicleType, category, route, i18n, axios, store, filters)

  return {
    filters,
    makes,
    models,
    modelsCount: vehicleModels?.nodesCount || 0,
    pagesCount: vehicles?.pagesCount || 0,
    seoContent,
    vehicles: vehicles?.nodes || [],
    vehiclesCount: vehicles?.nodesCount || 0,
  }
}
</script>
