export default ($axios) => ({
  list() {
    return $axios.$get('/v1/me/searches')
  },
  add(body) {
    return $axios.$post('/v1/me/searches', body)
  },
  delete(uid) {
    return $axios.$delete(`/v1/me/searches/${uid}`)
  },
  get(uid) {
    return $axios.$get(`/v1/me/searches/${uid}`)
  },
})
