const BASE_URL = '/v1'

export default ($axios) => ({
  /**
   * Attach document to an order.
   * @param {string} orderUid UID of the order.
   * @param {formData} formData formData to attach in the order.
   */
  attachDocument(orderUid, formData) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }
    return $axios.$post(`${BASE_URL}/orders/${orderUid}/documents`, formData, config)
  },
  /**
   * Get documents from the order.
   * @param {string} orderUid UID of the order.
   */
  async getDocuments(orderUid) {
    return await $axios.$get(`${BASE_URL}/orders/${orderUid}/documents`)
  },
  /**
   * Download specific document from  order.
   * @param {string} orderUid UID of the order.
   * @param {string} document type of document to download.
   */
  downloadDocument(orderUid, document) {
    return $axios
      .$post(`${BASE_URL}/orders/${orderUid}/documents/${document}/access`)
      .then((response) => response.url)
  },

  /**
   * Update the order to status order "documentation submited"
   * @param {string} orderUid UID of the order.
   */
  commitDocuments(orderUid) {
    return $axios.$post(`${BASE_URL}/orders/${orderUid}/documents/commit`)
  },
})
