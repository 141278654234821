<template>
  <div
    id="layoutsAuth"
    :class="[
      'has-lazy-background',
      'has-lazy-background--fixed',
      {
        '--feedback': isFeedbackPage,
        '--password-recovery-edit': isPasswordRecoveryEdit,
        '--password-recovery': isPasswordRecovery,
        '--sign-in-otp-expired': isSignInOtpExpired,
        '--sign-in-otp': isSignInOtp,
        '--sign-in': isSignIn,
        '--sign-up': isSignUp,
      },
    ]"
  >
    <main>
      <div class="container container--pv">
        <header class="f-light">
          <Icon name="astara_logo" size="14.5rem" class="s-mb-32" aria-label="Astara Store" />

          <template v-if="!isFeedbackPage">
            <template v-if="isSignIn">
              <p class="section-subtitle s-mb-16">
                {{ $t('pages.sign_in.index.header.title') }}
              </p>

              <p class="s-mb-16">
                {{ $t('pages.sign_in.index.header.description') }}
              </p>
            </template>
            <template v-else-if="isSignUp">
              <p class="section-subtitle s-mb-0">
                {{ $t('pages.sign_up.index.header.title') }}
              </p>

              <p class="section-subtitle f-500 s-mb-16">
                {{ $t('pages.sign_up.index.header.subtitle') }}
              </p>

              <p class="s-mb-16">
                {{ $t('pages.sign_up.index.header.description')[0] }}
              </p>

              <p class="s-mb-16" v-html="$t('pages.sign_up.index.header.description')[1]" />
            </template>
            <template v-else-if="isPasswordRecovery">
              <p class="section-subtitle s-mb-16">
                {{ $t('pages.password_recovery.index.header.title') }}
              </p>

              <p class="s-mb-16">
                {{ $t('pages.password_recovery.index.header.description') }}
              </p>
            </template>
            <template v-else-if="isPasswordRecoveryEdit">
              <p class="section-subtitle s-mb-16">
                {{ $t('pages.password_recovery.edit.header.title') }}
              </p>

              <p class="s-mb-16">
                {{ $t('pages.password_recovery.edit.header.description') }}
              </p>
            </template>
            <template v-else-if="isSignInOtp">
              <p class="section-subtitle s-mb-16">
                {{ $t('pages.sign_in.otp.index.header.title') }}
              </p>

              <p class="s-mb-16">
                {{ $t('pages.sign_in.otp.index.header.description') }}
              </p>
            </template>
            <template v-else-if="isSignInOtpExpired">
              <p class="section-subtitle s-mb-16">
                {{ $t('pages.sign_in.otp.expired.header.title') }}
              </p>

              <p class="s-mb-16">
                {{ $t('pages.sign_in.otp.expired.header.description') }}
              </p>
            </template>
          </template>

          <NuxtLink
            v-if="!isFeedbackPage"
            :to="localePath('index')"
            class="cta f-as-link f-as-link--secondary f-size-14"
            prefetch
          >
            <span> {{ $t('global.back_to_home_page') }}</span>
          </NuxtLink>
        </header>

        <div class="content-wrapper">
          <div class="content-wrapper__content">
            <nuxt />
          </div>
        </div>

        <NuxtLink
          v-if="!isFeedbackPage"
          :to="localePath('index')"
          class="cta f-as-link f-as-link--secondary f-size-14"
          prefetch
        >
          <span> {{ $t('global.back_to_home_page') }}</span>
        </NuxtLink>
      </div>
    </main>

    <picture class="has-lazy-background__background">
      <source media="(min-width: 1280px)" srcset="/images/layouts/auth/background/desktop.webp" />

      <img
        :alt="$t('modules.buying_power.alt')"
        decoding="async"
        fetchpriority="low"
        loading="lazy"
        src="/images/layouts/auth/background/tablet.webp"
      />
    </picture>
  </div>
</template>

<script>
// Components
import Icon from '@/components/Icon.vue'

export default {
  name: 'LayoutsAuth',
  scrollToTop: true,
  components: {
    Icon,
  },
  computed: {
    isFeedbackPage() {
      return this.$route.name.includes('create') || this.$route.name.includes('update')
    },
    isSignIn() {
      return this.$route.name.includes('sign-in') && !this.$route.name.includes('otp')
    },
    isSignUp() {
      return this.$route.name.includes('sign-up') && !this.$route.name.includes('create')
    },
    isPasswordRecovery() {
      return (
        this.$route.name.includes('password-recovery') &&
        !this.$route.name.includes('edit') &&
        !this.$route.name.includes('update')
      )
    },
    isPasswordRecoveryEdit() {
      return this.$route.name.includes('password-recovery-edit')
    },
    isSignInOtp() {
      return (
        this.$route.name.includes('sign-in-otp') &&
        !this.$route.name.includes('expired') &&
        !this.$route.name.includes('update')
      )
    },
    isSignInOtpExpired() {
      return this.$route.name.includes('sign-in-otp-expired')
    },
  },
  async fetch() {
    await this.$store.dispatch('campaign/extractQueryParams', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
}
</script>

<style lang="scss" scoped>
#layoutsAuth {
  --container-width: 70rem;

  main {
    display: flex;
    align-items: center;
    justify-content: center;

    > .container {
      position: relative;
      width: var(--container-width);
      min-height: 100vh;

      @supports (min-height: 100dvh) {
        min-height: 100dvh;
      }

      @include size-xl-up {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &:not(.--feedback) main > .container {
    @include size-xl-up {
      display: grid;
      grid-gap: 0 2rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;

      header {
        grid-column: 1;
        grid-row: 1 / 3;
        align-self: center;
        width: 26.5rem;
        justify-self: start;

        @media (height >= 500px) {
          display: flex;
          position: fixed;
          top: calc(50vh - 12.5rem);
          left: calc((100vw - min(var(--container-width), 100%)) * 0.5 + var(--s-ph));
          flex-direction: column;
          justify-content: center;
          height: 25rem;
        }
      }

      > .cta {
        display: none;
      }

      .content-wrapper {
        grid-column: 2;
        grid-row: 1 / 3;
        align-self: center;
        justify-self: end;
      }
    }

    @include size-l {
      display: block;

      header,
      .content-wrapper {
        margin-bottom: 2rem;
      }

      header .cta {
        display: none;
      }
    }

    > * {
      max-width: 100%;
    }
  }

  main > .container > header:deep(.icon) {
    height: 2.5rem;
  }

  main > .container > .content-wrapper {
    max-width: 100%;

    .content-wrapper__content {
      width: 100%;
      padding: 2.5rem;
      border-radius: 0.5rem;
      background-color: $c-neutral-050;
      box-shadow: $c-elevation-3;

      @include size-s {
        padding: 1.25rem;
      }
    }
  }

  &.--sign-up {
    --container-width: 90rem;

    > main > .container {
      @include size-l {
        width: calc(45rem + var(--s-ph) * 2);
      }

      @include size-xl-up {
        header {
          width: 33.75rem;
        }

        .content-wrapper {
          width: 41rem;
        }
      }
    }
  }

  &.--sign-in-otp > main > .container {
    @include size-l {
      width: calc(29.5rem + var(--s-ph) * 2);
    }

    .content-wrapper {
      &__content {
        padding: 0 !important;
      }

      @include size-xl-up {
        width: 33.75rem;
      }
    }
  }

  &.--sign-in > main > .container,
  &.--sign-in-otp-expired > main > .container,
  &.--feedback > main > .container,
  &.--password-recovery > main > .container,
  &.--password-recovery-edit > main > .container {
    @include size-l {
      width: calc(29.5rem + var(--s-ph) * 2);
    }

    .content-wrapper {
      @include size-xl-up {
        width: 26.75rem;
      }
    }
  }

  &:not(.--feedback) > main {
    @include size-l {
      align-items: flex-start;
    }
  }

  &.--feedback > main > .container {
    display: flex;
    align-items: center;
    justify-content: center;

    header {
      position: absolute;
      top: 4rem;
      left: 2.75rem;
      scroll-margin-top: var(--navbar-height);

      @include size-xl {
        left: 2.5rem;
      }

      @include size-l {
        left: 2rem;
      }

      @include size-m {
        top: 3.5rem;
        left: 1.5rem;
      }

      @include size-s {
        top: 3rem;
        left: 1.5rem;
      }

      @include size-xs {
        left: 1.25rem;
      }
    }
  }
}
</style>
