<template>
  <div class="internal-error">
    <div class="container container--pv-navbar">
      <p class="error-code f-500 f-size-14 f-secondary" data-cy="error-code">
        {{ errorNumber }}
      </p>
      <h1 class="f-500" data-cy="error-title">{{ $t(`pages.error.${errorType}.title`) }}</h1>
      <h3 class="f-300 f-size-24 text-primary" data-cy="error-description">
        <Icon class="icon" :name="iconName" />
        {{ $t(`pages.error.${errorType}.description[0]`) }}
      </h3>
      <p
        class="error-description f-500 f-size-18 f-secondary"
        data-cy="error-description"
        v-html="$t(`pages.error.${errorType}.description`)[1]"
      />
      <div v-if="errorType === '404'">
        <div class="error-categories">
          <NuxtLink
            data-cy="explore-moto"
            class="f-not-as-link selection-element"
            :to="localePath('moto')"
            @click.native="gtmRedirect('moto')"
          >
            <div class="category-description">
              <span class="f-size-14 f-500 f-uppercase">{{
                $t('global.categories_extended.motorbike')[1]
              }}</span>
              <span class="f-size-12 f-secondary">{{
                $t('pages.error.motorbike_description')
              }}</span>
            </div>
            <figure class="category-image moto">
              <img
                loading="lazy"
                src="/images/moto.webp"
                :alt="$t('global.categories_extended.motorbike')[1]"
              />
            </figure>
          </NuxtLink>

          <NuxtLink
            :to="localePath('car-vo')"
            class="f-not-as-link selection-element"
            data-cy="explore-vo0Cars"
            @click.native="gtmRedirect('car-vo')"
          >
            <div class="category-description">
              <span class="f-size-14 f-500 f-uppercase">{{
                $t('global.categories_extended.vo')[1]
              }}</span>
              <span class="f-size-12 text-secondary">{{ $t('pages.error.vo_description') }}</span>
            </div>
            <figure class="category-image">
              <img
                loading="lazy"
                src="/images/car_used.webp"
                :alt="$t('global.categories_extended.vo')[0]"
              />
            </figure>
          </NuxtLink>

          <NuxtLink
            :to="localePath('car-km0')"
            class="f-not-as-link selection-element"
            data-cy="explore-km0Cars"
            @click.native="gtmRedirect('car-km0')"
          >
            <div class="category-description">
              <span class="f-size-14 f-500 f-uppercase">{{
                $t('global.categories_extended.km0')[1]
              }}</span>
              <span class="f-size-12 text-secondary">{{ $t('pages.error.km0_description') }}</span>
            </div>
            <figure class="category-image">
              <img
                loading="lazy"
                src="/images/car_used.webp"
                :alt="$t('global.categories_extended.km0')[0]"
              />
            </figure>
          </NuxtLink>

          <NuxtLink
            :to="localePath('car-vn')"
            class="f-not-as-link selection-element"
            data-cy="explore-newCars"
            @click.native="gtmRedirect('car-vn')"
          >
            <div class="category-description">
              <span class="f-size-14 f-500 f-uppercase">{{
                $t('global.categories_extended.vn')[1]
              }}</span>
              <span class="f-size-12 text-secondary">{{ $t('pages.error.vn_description') }}</span>
            </div>
            <figure class="category-image">
              <img
                loading="lazy"
                src="/images/car_vn.webp"
                :alt="$t('global.categories_extended.vn')[0]"
              />
            </figure>
          </NuxtLink>
        </div>
      </div>
      <div
        v-if="errorType === 'default' || errorType === 'purchase'"
        class="error-contact-info"
        data-cy="error-contact-info"
      >
        <div class="f-as-link f-as-link--with-icon">
          <Icon name="chat" />
          <span>
            {{ $t(`pages.error.${errorType}.chat`) }}
          </span>
        </div>

        <a class="f-as-link f-as-link--with-icon" href="mailto:help@astarastore.com">
          <Icon name="email" />
          <span>
            {{ $t(`pages.error.${errorType}.email`) }}
          </span>
        </a>

        <a class="f-as-link f-as-link--with-icon" :href="`tel:${contactPhone}`">
          <Icon name="phone" />
          <span>
            {{ contactPhone }}
          </span>
        </a>
      </div>
      <Button
        v-if="errorType === 'financing'"
        type-theme="primary-light"
        size="large"
        data-cy="error-button"
        :to="localePath('index')"
      >
        {{ $t(`pages.error.${errorType}.cta`) }}
      </Button>
      <Button
        v-if="errorType === 'purchase'"
        type-theme="primary-light"
        size="large"
        data-cy="error-button"
        @click="$emit('tryAgain')"
      >
        {{ $t(`pages.error.${errorType}.cta`) }}
      </Button>
    </div>
  </div>
</template>

<script>
// Components
import Button from '@/components/Button'
import Icon from '@/components/Icon'

// Other
export const ERRORTYPES = ['default', '404', 'financing', 'purchase']

export default {
  components: { Button, Icon },
  props: {
    errorType: {
      type: String,
      required: false,
      validator: (value) => ERRORTYPES.includes(value),
      default: 'default',
    },
    errorCode: {
      type: [String, Number],
      required: false,
      default: '999',
    },
  },
  data() {
    return {
      contactPhone: process.env.contactPhone,
    }
  },
  computed: {
    iconName() {
      if (this.errorType === '404') return 'map'
      else if (this.errorType === 'financing') return 'schedule_send'
      else if (this.errorType === 'purchase') return 'portable_wifi_off'
      else return 'sentiment_very_dissatisfied'
    },
    errorNumber() {
      return this.errorType === 'default'
        ? this.$t(`pages.error.${this.errorType}.error_number`, { number: this.errorCode })
        : this.$t(`pages.error.${this.errorType}.error_number`)
    },
  },
  methods: {
    gtmRedirect(route) {
      if (route === 'moto') {
        if (process?.browser && process?.env?.gtm_enabled) {
          this.$gtm.push({ event: 'view_motorbike_page' })
        }
      }
      if (route === 'car-km0') {
        if (process?.browser && process?.env?.gtm_enabled) {
          this.$gtm.push({ event: 'view_km0_page' })
        }
      }
      if (route === 'car-vn') {
        if (process?.browser && process?.env?.gtm_enabled) {
          this.$gtm.push({ event: 'view_newcar_page' })
        }
      }
      if (route === 'car-vo') {
        if (process?.browser && process?.env?.gtm_enabled) {
          this.$gtm.push({ event: 'view_vo_page' })
        }
      }

      this.$router.push(this.localePath(route))
    },
  },
  head() {
    return this.$generatePageMetasFromKey('pages.error')
  },
}
</script>

<style lang="scss" scoped>
.internal-error {
  min-height: 100vh;
  background-image: url('/images/error-bg.webp');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @include size-s {
    background-image: url('/images/error-bg-mobile.webp');
    text-align: left;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .error-code {
      margin: 0;
      padding: 0.25rem 0.5rem;
      border: 1px solid $text-secondary;
      border-radius: 0.875rem;
    }

    h1 {
      margin: 1.5rem 0 0.5rem;
      color: $highlight-1;
      font-size: $font-size-5xl;
      @include size-m {
        font-size: $font-size-3xl;
      }
      @include size-xs {
        font-size: $font-size-2xl;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      margin-bottom: 4rem;

      .icon {
        margin-right: 0.5rem;
      }

      @include size-xs {
        margin-bottom: 3rem;
      }
    }

    p {
      margin: 0;
    }

    .error-description {
      @include size-xs {
        font-size: $font-size-base;
      }
    }

    .error-categories {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      margin-top: 2.5rem;

      @include size-l {
        gap: 1.5rem;
      }

      @include size-m {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      @include size-s {
        gap: 1.25rem;
      }

      @include size-s {
        display: flex;
        flex-direction: column;
      }

      @include size-xs {
        margin-top: 1.5rem;
      }

      .selection-element {
        display: flex;
        gap: 0.25rem;
        flex: 1;
        padding: 1rem;
        border: solid 1px $c-primary-200;
        border-radius: 4px;
        background-color: $c-neutral-050;
        box-shadow: $c-elevation-1;
        cursor: pointer;

        &:hover {
          border: solid 1px $highlight-1;
          background-color: $c-neutral-200;
          box-shadow: $c-elevation-2;
        }

        .category-description {
          display: flex;
          gap: 0.25rem;
          flex: 1;
          flex-direction: column;
        }

        .category-image {
          display: flex;
          justify-content: center;
          width: 8.6875rem;
          height: 4rem;
          margin: auto;

          &.moto {
            width: 8rem;
            height: 5rem;
          }

          @include size-l {
            width: 7.375rem;
            height: 4rem;
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .error-contact-info {
      display: inline-flex;
      align-items: center;
      margin-top: 2.5rem;

      @include size-xs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1.5rem;
      }

      .f-as-link {
        margin: 0 0.375rem 0 0;

        &:not(:first-child) {
          &::before {
            content: ' | ';
            margin: 0 1rem;
            color: $text-secondary;

            @include size-xs {
              display: none;
            }
          }

          @include size-xs {
            margin-top: 1.5rem;
          }
        }

        @include size-xs {
          flex-direction: row;
        }
      }
    }
  }
}
</style>
