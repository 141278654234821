<template>
  <div class="submenu size-14" :class="{ 'submenu--open': open }" @mouseout="onDropdownMouseout">
    <div class="submenu__header" @mouseover="onDropdownMouseover" @click="onHeaderClick">
      <div class="submenu__header__title">
        <slot name="header"></slot>
      </div>
      <Icon :name="open ? 'expand_less' : 'expand_more'" size="1.5rem" />
    </div>
    <div data-cy="submenu-dropdown" class="submenu__dropdown" @mouseover="onDropdownMouseover">
      <div class="submenu__dropdown__menu" @click="onMenuClick">
        <slot name="menu"></slot>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Icon from '~/components/Icon.vue'

export default {
  name: 'PartialSubmenu',
  components: {
    Icon,
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    onDropdownMouseover() {
      if (window.innerWidth >= 1024) {
        this.open = true
      }
    },
    onDropdownMouseout() {
      if (window.innerWidth >= 1024) {
        this.open = false
      }
    },
    onHeaderClick() {
      this.open = !this.open
    },
    onMenuClick() {
      setTimeout(() => {
        this.open = false
      }, 800)
    },
  },
}
</script>

<style lang="scss" scoped>
.submenu {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: center;

  &__context {
    position: absolute;
    z-index: -1;
    top: -100%;
    left: -100%;
    width: 200%;
    height: 200%;
  }

  &__header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    padding: 0.5rem 0.6875rem;
    transition: background-color 0.2s ease;
    border-radius: 0.5rem;
    background-color: $submenu-header-background-color;
    text-transform: uppercase;
    cursor: pointer;

    > * {
      pointer-events: none;
    }

    &__title {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      color: $submenu-header-color;
      font-weight: 500;
      gap: 0.625rem;
    }

    .icon {
      display: block;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 1;
    top: 2.25rem;
    right: 0;
    width: 14.8125rem;
    transition: opacity 0.2s ease;

    &__menu {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1rem;
      transition: background-color 0.2s ease;
      border: 1px solid $submenu-border-color;
      border-radius: 0.5rem;
      background: $submenu-background-color;
      box-shadow: 0 20px 40px rgba(17, 21, 28, 0.1), 0 8px 12px rgba(17, 21, 28, 0.0607407),
        0 2px 4px rgba(17, 21, 28, 0.0392593);

      :deep(a) {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        padding: 0.875rem 0;
        text-decoration: none !important;
        white-space: nowrap;
        cursor: pointer;

        > * {
          pointer-events: none;
        }

        &:not(:first-child) {
          border-top: 1px solid $submenu-border-color;
        }

        .icon,
        span {
          transition: color 0.3s ease;
          color: $submenu-color;
        }

        .icon {
          display: block;
          font-size: 1.25rem;
        }

        span {
          text-transform: uppercase;
        }

        &:hover {
          .icon,
          span {
            color: $submenu-color-hover;
          }
        }
      }
    }
  }

  &:not(.submenu--open) .submenu__dropdown {
    opacity: 0;
    pointer-events: none;
  }

  @include size-xl-up {
    &--open {
      @include size-xl-up {
        .submenu__header {
          background-color: $submenu-header-background-color-open;
          color: $submenu-header-color-open;

          :deep(span),
          :deep(.icon) {
            color: $submenu-header-color-open;
          }
        }
      }
    }

    &__header > .icon {
      display: none;
    }
  }

  @include size-l {
    width: 100%;
    padding: 0;

    &__header,
    &__dropdown {
      width: 100%;
    }

    &__header {
      padding: 0;
    }

    .submenu__dropdown {
      top: 2rem;
    }
  }
}
</style>
