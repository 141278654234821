export default ({ store }, inject) => {
  const blackFridayStartDate = new Date('2023-11-16T08:30:00')
  const blackFridayEndDate = new Date('2023-12-05T22:59:00')

  const showroompriveStartDate = new Date('2024-08-26T00:00:30')
  const showroompriveEndDate = new Date('2024-09-02T23:59:00')

  const isDateInRange = (useCase, start, end) => {
    if (store.getters['campaign/getTesting'] === useCase) {
      return true
    }

    const currentDate = new Date()
    return currentDate >= start && currentDate <= end
  }

  inject('isBlackFriday', () => {
    return isDateInRange('black_friday', blackFridayStartDate, blackFridayEndDate)
  })

  inject('isShowroomPrive', () => {
    return isDateInRange('showroomprive', showroompriveStartDate, showroompriveEndDate)
  })

  inject('activeCampaigns', () => {
    const campaigns = {}

    campaigns.black_friday = isDateInRange('black_friday', blackFridayStartDate, blackFridayEndDate)

    campaigns.showroomprive = isDateInRange(
      'showroomprive',
      showroompriveStartDate,
      showroompriveEndDate,
    )

    return campaigns
  })

  inject('isPromotedForBlackFriday', (useCases) => {
    try {
      return (
        isDateInRange('black_friday', blackFridayStartDate, blackFridayEndDate) &&
        useCases &&
        useCases.includes('black_friday')
      )
    } catch {
      return false
    }
  })

  inject('isPromotedForShowroomPrive', (useCases) => {
    try {
      return (
        isDateInRange('showroomprive', showroompriveStartDate, showroompriveEndDate) &&
        useCases &&
        useCases.includes('showroomprive')
      )
    } catch {
      return false
    }
  })

  inject('isPromoted', (useCases) => {
    try {
      return (
        useCases &&
        useCases.some((useCase) => ['offer', 'salon_vo', 'summer_days'].includes(useCase))
      )
    } catch {
      return false
    }
  })
}
