<template>
  <main id="layoutMaintenance">
    <Navbar />
    <nuxt />
  </main>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'LayoutsMaintenance',
  scrollToTop: true,
  components: { Navbar },
  async fetch() {
    await this.$store.dispatch('campaign/extractQueryParams', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
}
</script>

<style lang="scss" scoped>
#layoutMaintenance main {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }
}
</style>
