<template>
  <div id="layoutsLegal" class="legal-page">
    <Navbar />

    <main>
      <nuxt />
    </main>

    <Footer />
  </div>
</template>

<script>
// Components
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'LayoutsLegal',
  scrollToTop: true,
  components: {
    Navbar,
    Footer,
  },
  async fetch() {
    await this.$store.dispatch('campaign/extractQueryParams', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
}
</script>
