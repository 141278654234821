export default ($axios) => ({
  list() {
    return $axios.$get('/v1/me/favourites')
  },
  add(favouriteMarkableId, favouriteMarkableType) {
    return $axios.$post('/v1/me/favourites', {
      favourite_markable_id: favouriteMarkableId,
      favourite_markable_type: favouriteMarkableType,
    })
  },
  delete(uid) {
    return $axios.$delete(`/v1/me/favourites/${uid}`)
  },
})
