<template>
  <div id="layoutsDefaultWithoutNewsletter">
    <Navbar />

    <main>
      <nuxt />
    </main>

    <Footer />
  </div>
</template>

<script>
// Components
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'LayoutsDefaultWithoutNewsletter',
  scrollToTop: true,
  components: {
    Footer,
    Navbar,
  },
  async fetch() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('campaign/extractQueryParams', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
}
</script>

<style lang="scss" scoped>
#layoutsDefaultWithoutNewsletter main {
  min-height: calc(100vh - 36.125rem);
}
</style>
