<template>
  <div class="favourite-wrapper" :style="`z-index: ${showModalsSign ? 99999999 : 3}`">
    <!-- Modal for actions that require the customer to be logged in -->
    <ModalsSign
      :open="showModalsSign"
      type="favourites"
      class="sign-modal-form"
      @close="() => (showModalsSign = false)"
      @submit="() => toggle()"
    />

    <button
      :class="[
        'favourite-action',
        { 'favourite-action--disabled': loading || !favouriteMarkableId || !favouriteMarkableType },
      ]"
      @click.capture="() => toggle()"
    >
      <Icon :name="iconName" size="1.25rem" />
      <span v-if="showLabel" class="favourite-action__label">
        {{ $t(`pages.favourites.cta.${status ? 'remove' : 'add'}`) }}
      </span>
    </button>
  </div>
</template>

<script>
// Dependencies
import { mapGetters, mapActions } from 'vuex'

// Components
import Icon from '@/components/Icon.vue'
import ModalsSign from '@/components/modals/Sign.vue'

export default {
  name: 'PartialFavourite',
  components: {
    Icon,
    ModalsSign,
  },
  props: {
    favouriteMarkableId: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    favouriteMarkableType: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      status: false,
      showModalsSign: false,
      favouriteUid: undefined,
    }
  },
  watch: {
    loggedIn: {
      immediate: false,
      handler(newVal, _) {
        if (newVal) {
          this.getFavouriteUid()
        } else {
          this.status = false
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      userFavourites: 'auth/favourites',
      loggedIn: 'auth/loggedIn',
    }),
    iconName() {
      return this.status ? 'favorite' : 'favorite_border'
    },
  },
  methods: {
    ...mapActions({
      refreshUser: 'auth/refreshUser',
    }),
    getFavouriteUid() {
      try {
        if (this.loggedIn && this.userFavourites) {
          this.userFavourites?.forEach((item) => {
            if (
              item.favouriteMarkableId === this.favouriteMarkableId &&
              item.favouriteMarkableType === this.favouriteMarkableType
            ) {
              this.favouriteUid = item.uid
              this.status = true
            }
          })
        }
      } catch {
        return undefined
      }
    },
    async toggle() {
      if (this.loading || !this.favouriteMarkableId || !this.favouriteMarkableType) return

      let justLogged = false

      if (!this.loggedIn) {
        this.showModalsSign = true
        return
      } else if (this.showModalsSign) {
        this.showModalsSign = false
        justLogged = true
      }

      if (this.status && justLogged) return

      this.loading = true

      // SEO
      let gtmData = {}
      if (this.options) {
        gtmData = {
          make: this.options?.makeName,
          model: this.options?.modelName,
          product: this.options?.category,
        }
      }
      this.$tracker.trackEvent('select_favorite', gtmData, undefined)

      try {
        if (!this.status) {
          const response = await this.$favouritesService.add(
            this.favouriteMarkableId,
            this.favouriteMarkableType,
          )

          this.refreshUser()

          this.$displayNotification(
            'success',
            '',
            this.$t('messages.success.added_favourite.message'),
            [
              {
                label: this.$t('global.check_out'),
                to: this.localePath('favourites'),
              },
            ],
            true,
          )

          // SEO
          this.$tracker.trackEvent('favorite_confirmation', gtmData, undefined)

          this.favouriteUid = response.uid
          this.status = true
        } else {
          await this.$favouritesService.delete(this.favouriteUid)

          this.refreshUser()

          this.$displayNotification(
            'success',
            '',
            this.$t('messages.success.deleted_favourite.message'),
            undefined,
            true,
          )

          this.status = false
        }
      } catch {
        // eslint-disable-next-line no-console
        console.error('An error occurred while trying to transition the favourite state.')
        this.$displayPredefinedNotification('error', 'service')
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    if (this.loggedIn) {
      try {
        this.getFavouriteUid()
      } catch {
        this.status = false
      } finally {
        this.loading = false
      }
    } else {
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.favourite-action {
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  cursor: pointer;

  .icon {
    color: $c-primary-600;
  }

  &--disabled {
    filter: grayscale(100%);
    cursor: not-allowed;
  }

  &__label {
    color: $c-primary-600;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-transform: uppercase;

    @include size-m {
      display: none;
    }
  }
}
</style>
