import { localize as veeLocalize } from 'vee-validate'

/**
 * This plugin provides the following five methods:
 *
 * getValidCountry
 *    Params:
 *      - @param {Object[]} countries: a list of valid countries.
 *
 *    Returns:
 *      - Object: country hash as defined in config (config/index).
 *
 *    Description: tries to get a valid country from cookies, or URL.
 *
 * getValidLanguage
 *    Params:
 *      - @param {String} country: country iso code as defined in config (config/index).
 *      - @param {String[]} languages: a list of valid languages.
 *
 *    Returns:
 *      - String: language iso as defined in locales config (config/locales/config).
 *
 *    Description: tries to get a valid language from cookies, browser or URL.
 *
 * setValidCountry (async)
 *    Params:
 *      - @param {Object} country: country hash as defined in config (config/index).
 *      - @param {Boolean} redirectAfter: if true, redirects to language-selector or home page.
 *
 *    Description: given a country, saves it in the store,
 *    sets the cookie, set axios header, gets the company info and checks its status.
 *    Then, generates the list of available languages for the country.
 *
 * setValidLanguage
 *    Params:
 *      - @param {String} language: language iso as defined in locales config (config/locales/config).
 *      - @param {Boolean} redirectAfter: if true, redirects to home page.
 *
 *    Description: given a language, saves it in the store,
 *    sets the cookie, set axios header, calls setLocale() from i18n to set the
 *    site language and sets Vee-Validate locale.
 */

export default ({ app, store, $cookiez, redirect, route, req, $axios }, inject) => {
  function move(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += arr.length
    }
    while (newIndex < 0) {
      newIndex += arr.length
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length
      while (k-- + 1) {
        arr.push(undefined)
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
    return arr
  }
  const localize = {
    getValidCountry: (countries) => {
      let country
      if (route.path === '/') {
        country = $cookiez.get('preferred_country')
      } else if (/[a-zA-Z]{2}_[a-zA-Z]{2}/.test(route.path)) {
        // Get from URL: /country_LANGUAGE

        country = route.path.split('/')[1].split('_')[0]
      } else if (/[a-zA-Z]{2}/.test(route.path)) {
        // Get from URL: /country
        country = route.path.split('/')[1]
      }
      if (country) country = country.toLowerCase()

      return country && Object.keys(countries).includes(country) ? countries[country] : null
    },
    getValidLanguage: (country, languages) => {
      if (route.name !== 'country-select-language') {
        let language

        if (route.path === '/') {
          // Get from cookies
          language = $cookiez.get('preferred_language')

          if (!language) {
            // Get from user
            language = `${country}_${
              req.headers['accept-language']?.split(';')[0]?.substring(0, 2)?.toUpperCase() || 'ES'
            }`
          }
        } else if (/[a-zA-Z]{2,}_[a-zA-Z]{2,}/i.test(route.path)) {
          // Get from URL: /country_LANGUAGE
          language = route.path.split('/')[1]
        }

        return language && languages.find((l) => l.code === language) ? language : null
      }

      return null
    },
    setValidCountry: async (country, redirectAfter = false) => {
      // Set country
      store.commit('setCountry', country.iso)
      store.commit('setApiKey', country.apiKey)

      $cookiez.set('preferred_country', country.iso)

      let companyLanguages = []

      // Get company
      await app.$purchaseService.getCompany().then((company) => {
        // Set company
        store.commit('setAlive', company.alive)

        // Get company available languages
        companyLanguages = company.languages
      })

      // Set country available languages
      const countryLanguages = app.i18n.locales
        .filter(
          (local) =>
            local.code.split('_')[0] === country.iso && companyLanguages.includes(local.iso),
        )
        .map((local) => {
          return { code: local.code, iso: local.iso, name: local.name }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
      if (country.defaultLanguage) {
        move(
          countryLanguages,
          countryLanguages.findIndex((lang) => lang.iso === country.defaultLanguage),
          0,
        )
      }

      store.commit('setCountryLanguages', countryLanguages)

      // Redirect
      if (redirectAfter) {
        if (countryLanguages.length === 1) {
          app.$localize.setValidLanguage(this.countryLanguages[0].code)
          redirect(301, app.localePath('index'))
        } else {
          redirect(`/${country.iso}/select-language`)
        }
      }
    },
    setValidLanguage: (language, redirectAfter = false) => {
      try {
        const languageIso = language.split('_')[1].toLowerCase()

        // Set language
        store.commit('setLanguage', language)
        store.commit('setLanguageIso', languageIso)
        app.i18n.setLocale(language)
        app.i18n.locale = language

        $cookiez.set('preferred_language', language)

        veeLocalize(languageIso)

        // Redirect
        if (redirectAfter) {
          redirect(301, app.localePath('index'))
        }
      } catch {
        redirect('/select-country')
      }
    },
  }

  inject('localize', localize)
}
