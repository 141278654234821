<template>
  <main id="layoutsEmpty">
    <nuxt />

    <Notifications />
  </main>
</template>

<script>
// Components
import Notifications from '@/components/Notifications.vue'

export default {
  name: 'LayoutsEmpty',
  scrollToTop: true,
  components: {
    Notifications,
  },
  async fetch() {
    await this.$store.dispatch('campaign/extractQueryParams', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
}
</script>
