// This middleware makes sure the page is not under maintenance.
// Otherwise it will redirect the user to /maintenance

export default function ({ route, redirect, store }) {
  if (store.state.alive === false && route.path !== '/maintenance') {
    redirect(this.localePath('maintenance'))
  } else if (store.state.alive === true && route.path === '/maintenance') {
    redirect(this.localePath('index'))
  }
}
