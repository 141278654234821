<!-- eslint-disable vue/valid-v-for -->
<template>
  <div
    :data-cy="`${vehicleType}-${category}-catalog`"
    :style="showFilters ? 'overflow: hidden' : undefined"
    class="catalog"
  >
    <Loader fixed :loading="loading" />

    <Navbar />

    <ModulesAstaraBenefitsSlider />

    <!-- Modal for actions that require the customer to be logged in -->
    <ModalsSign
      :open="showModalsSign"
      :type="signFormType"
      @close="toggleModalsSign(false)"
      @submit="afterSignActionDispatcher"
    />

    <!-- Header -->
    <header class="catalog__header">
      <div class="container">
        <Breadcrumb
          v-if="breadcrumbLinks?.length"
          :home-link="isMove ? 'astara-selection' : undefined"
          :breadcrumb-links="breadcrumbLinks.slice(0, breadcrumbLinks.length - 1)"
          :current-page="breadcrumbLinks[breadcrumbLinks.length - 1].name"
        />

        <div class="catalog__header__title">
          <h1 v-if="!!seoContent?.header?.title" class="title">
            {{ seoContent.header.title }}
          </h1>

          <div v-if="subtitle" class="subtitle" v-html="subtitle" />

          <PartialWarrantyBadge :type="isVo ? 'astara_one_year' : 'manufacturer'" />
        </div>

        <!-- Mobile and desktop filters -->
        <FiltersMobile
          class="catalog__header__filters"
          :can-save-alert="canSaveAlert"
          :can-save-search="canSaveSearch"
          :current-search-saved="currentSearchSaved"
          :disabled="loading"
          :options="sortingFilters"
          :selection="sortingFiltersStatus"
          @filter="toggleFilters(true)"
          @submit="(value) => onSortBy(value)"
          @newAlert="onNewAlert"
          @saveSearch="onPerformProtectedAction('saved_searches')"
        />
      </div>
    </header>

    <!-- Body -->
    <main class="catalog__main">
      <div class="container grid-responsive">
        <!-- Filters -->
        <Filters
          :category="category"
          :disabled="loading"
          :filters="filters"
          :hidden-filters="hiddenFilters"
          :makes="makes"
          :models="models"
          :open="showFilters"
          :vehicle-type="vehicleType"
          :vehicles-count="vehiclesCount"
          :vehicles-number="vehiclesCount"
          class="catalog__main__filters"
          @close="toggleFilters(false)"
          @loading="(value) => toggleLoading(value)"
          @clearFilters="onClearFilters"
          @submit="(value) => onSubmit(value)"
        />

        <!-- Main content -->
        <div class="catalog__main__results" data-cy="catalog-results">
          <div class="catalog__main__results__header">
            <div
              class="catalog__main__results__header__top container-flex container-flex--justify-space-between"
            >
              <div class="container-buttons">
                <!-- Add alert action -->
                <Button
                  :state="canSaveAlert"
                  icon="notification_add"
                  size="medium"
                  type-theme="secondary-light"
                  @click="onNewAlert"
                >
                  <span>
                    {{ $t('pages.alerts.cta.add') }}
                  </span>
                </Button>

                <!-- Save search action -->
                <Button
                  :icon="currentSearchSaved ? 'bookmark' : 'bookmark_border'"
                  :state="canSaveSearch"
                  :style="currentSearchSaved ? 'pointer-events: none;' : ''"
                  :label="$t(`pages.saved_searches.cta.${currentSearchSaved ? 'added' : 'add'}`)"
                  size="medium"
                  type-theme="secondary-light"
                  @click="onPerformProtectedAction('saved_searches')"
                />
              </div>

              <!-- Sorting -->
              <ButtonGroup
                class="filters-toggle"
                data-cy="explore-brands"
                size="small"
                :options="sortingFilters"
                :selection="sortingFiltersStatus"
                :disabled="loading"
                @submit="(value) => onSortBy(value)"
              />

              <!-- Tablet filters -->
              <FiltersMobile
                class="catalog__main__results__header__top__filters"
                :can-save-alert="canSaveAlert"
                :can-save-search="canSaveSearch"
                :current-search-saved="currentSearchSaved"
                :disabled="loading"
                :options="sortingFilters"
                :selection="sortingFiltersStatus"
                @filter="toggleFilters(true)"
                @submit="(value) => onSortBy(value)"
                @newAlert="onNewAlert"
                @saveSearch="onPerformProtectedAction('saved_searches')"
              />
            </div>

            <!-- Results counting -->
            <div
              v-show="vehicles?.length > 0 && !loading"
              class="catalog__main__results__header__total-vehicles"
              data-cy="total-vehicles"
            >
              <span class="f-size-18 f-500">
                <span class="total">
                  {{
                    $tc(
                      `pages.catalog.total_${isMotorbike ? 'motos' : 'cars'}`,
                      vehiclesCount || 0,
                      {
                        total: vehiclesCount || '0',
                      },
                    )
                  }}
                </span>
                <span v-if="!isMotorbike" v-show="modelsCount > 0">
                  {{
                    $tc('pages.catalog.total_models', modelsCount || 0, {
                      total: modelsCount || '0',
                    })
                  }}
                </span>
                {{ $tc('pages.catalog.waiting', vehiclesCount || 0) }}
              </span>
            </div>

            <Spinner v-show="loading" />

            <!-- Active filters -->
            <template v-if="activeFilters?.length">
              <div class="catalog__main__results__header__active-filters">
                <div class="tags" data-cy="active-filters-tags">
                  <Tag
                    v-for="filter in activeFilters"
                    :key="filter.key"
                    :label="filter.value"
                    class="upper-text"
                    data-cy="filter-tag"
                    show-delete-action
                    theme="primary"
                    @delete="onClearFilter(filter.key)"
                  />
                </div>
                <div
                  v-if="Object.keys(filters).length > 0"
                  class="clean-filters-button f-size-16 f-500"
                  @click="onClearFilters"
                >
                  {{ $t('filters.labels.clean_filters') }}
                </div>
              </div>
            </template>
          </div>

          <!-- Vehicles list -->
          <div v-show="vehicles?.length > 0" class="container-catalog-list">
            <template v-for="(vehicle, index) in vehicles">
              <CatalogCard
                :vehicle="vehicle"
                :category="category"
                :level="isMotorbike ? 'version' : isVn ? 'model' : 'vehicle'"
                :gtm-data="getCardEventData(vehicle).gtm"
                :datalayer-data="getCardEventData(vehicle).datalayers"
              />

              <CatalogCardUSP
                v-if="randomUspCards && [3, 10].includes(index)"
                :type="randomUspCards[index === 3 ? 0 : 1]"
              />
            </template>
          </div>

          <!-- If there are not results to show... -->
          <div
            v-show="vehicles?.length === 0 && !loading"
            class="catalog__main__results__empty"
            data-cy="catalog-empty"
          >
            <Icon name="street_blocked" size="5.5rem" />

            <h3 class="f-300">
              {{ $t('pages.catalog.empty.title') }}
            </h3>

            <p class="f-size-18 f-500 f-secondary">
              {{ $t('pages.catalog.empty.description')[0] }}
            </p>

            <p class="f-size-18 f-500 f-secondary">
              {{ $t('pages.catalog.empty.description')[1] }}
            </p>

            <Button
              type-theme="primary-light"
              :label="$t('pages.catalog.empty.restart_filter')"
              @click="onClearFilters"
            />
          </div>

          <Pagination
            v-show="pagesCount > 1"
            v-model="page"
            :page-count="pagesCount"
            :margin-pages="1"
            :page-range="3"
            @input="(value) => onPaginate(value)"
          />

          <!-- Legal notice -->
          <div
            class="catalog__main__results__legal-notice text-secondary size-12"
            data-cy="legal-notice"
            v-html="$t('pages.catalog.financing_legal_text')"
          />

          <div class="alert-footer">
            <Button
              :label="$t('pages.alerts.cta.add')"
              icon="notification_add"
              subtype="icon-text"
              type-theme="secondary-light"
              @click="onNewAlert"
            />

            <p>{{ $t('pages.alerts.description') }}</p>
          </div>
        </div>
      </div>
    </main>

    <section
      v-show="!!seoContent?.characteristicsContent"
      class="information-section container container--pv"
    >
      <div class="grid-responsive">
        <div class="content-wrapper">
          <SeoContent :content="seoContent.characteristicsContent" />
        </div>
      </div>
    </section>

    <NewsletterModule />

    <Footer />
  </div>
</template>

<script>
// Dependencies
import { mapGetters, mapActions } from 'vuex'

// Plugins
import { customSanitizer } from '@/plugins/sanitizer'

// Helpers & mixins
import { toCurrency } from '@/helpers/currency'
import { getBaseUrl, getFullPageUrl } from '@/helpers/urls'
import { getRandomUspTypes, getFitersState } from '@/helpers/catalog'
import { normaliseData } from '@/helpers/vehicleDataNormalisation'
import { toThousand } from '@/helpers/numbers'

// Components
import Breadcrumb from '@/components/Breadcrumb.vue'
import Button from '@/components/Button.vue'
import ButtonGroup from '@/components/ButtonGroup.vue'
import CatalogCard from '@/components/catalog/Card.vue'
import CatalogCardUSP from '@/components/catalog/CardUSP.vue'
import Filters from '@/components/catalog/Filters.vue'
import FiltersMobile from '@/components/catalog/FiltersMobile.vue'
import Footer from '@/components/Footer.vue'
import Icon from '@/components/Icon.vue'
import Loader from '@/components/Loader.vue'
import ModulesAstaraBenefitsSlider from '@/components/modules/AstaraBenefitsSlider.vue'
import Navbar from '@/components/Navbar.vue'
import NewsletterModule from '@/components/modules/Newsletter.vue'
import Pagination from '@/components/Pagination.vue'
import PartialWarrantyBadge from '@/components/partials/WarrantyBadge.vue'
import SeoContent from '@/components/catalog/SeoContent.vue'
import Spinner from '@/components/Spinner.vue'
import ModalsSign from '@/components/modals/Sign.vue'
import Tag from '@/components/Tag.vue'

export default {
  name: 'CatalogPage',
  layout: 'empty',
  middleware: ['catalogRoute'],
  components: {
    Breadcrumb,
    Button,
    ButtonGroup,
    CatalogCard,
    CatalogCardUSP,
    Filters,
    FiltersMobile,
    Footer,
    Icon,
    Loader,
    ModulesAstaraBenefitsSlider,
    Navbar,
    NewsletterModule,
    Pagination,
    PartialWarrantyBadge,
    SeoContent,
    Spinner,
    ModalsSign,
    Tag,
  },
  props: {
    vehicleType: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    vehicles: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    vehiclesOnOfferCount: {
      type: [Number, undefined],
      required: false,
      default: undefined,
    },
    vehiclesCount: {
      type: [Number, undefined],
      required: false,
      default: undefined,
    },
    modelsCount: {
      type: [Number, undefined],
      required: false,
      default: undefined,
    },
    pagesCount: {
      type: Number,
      required: false,
      default: 1,
    },
    makes: {
      type: Array,
      required: true,
    },
    models: {
      type: [Array, undefined],
      required: false,
      default: undefined,
    },
    seoContent: {
      type: [Object, undefined],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      currentSearchSaved: false,
      loading: true,
      showFilters: false,
      page: 0,
      randomUspCards: undefined,
      showModalsSign: false,
      signFormType: '',
      subtitle: '',
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      getModels: 'catalog/getModels',
    }),
    isMove() {
      return this.vehicleType === 'car' && this.category === 'move'
    },
    isVn() {
      return this.vehicleType === 'car' && this.category === 'vn'
    },
    isVo() {
      return this.vehicleType === 'car' && this.category === 'vo'
    },
    isKm0() {
      return this.vehicleType === 'car' && this.category === 'km0'
    },
    isMotorbike() {
      return ['moto', 'motorbike', 'motorcycle'].includes(this.vehicleType)
    },
    matchedMake() {
      try {
        const makeSlug = this.filters.makeSlug

        if (!makeSlug) return

        return this.makes?.find((make) => make[0] === makeSlug)
      } catch {
        return undefined
      }
    },
    breadcrumbLinks() {
      try {
        const name = this.$t(
          `global.categories_extended.${this.isMotorbike ? 'motorbike' : this.category}`,
        )[1]

        if (!this.matchedMake) {
          return [
            {
              name,
            },
          ]
        } else {
          let url

          if (this.isMotorbike) {
            url = 'motorbikes'
          } else if (this.isVn) {
            url = 'cars-vn'
          } else if (this.isKm0) {
            url = 'cars-km0'
          } else if (this.isMove) {
            url = 'astara-selection-cars'
          } else {
            url = 'cars-vo'
          }

          return [
            {
              name,
              url,
            },
            {
              name: this.matchedMake[1],
            },
          ]
        }
      } catch {
        return []
      }
    },
    activeFilters() {
      try {
        if (Object.keys(this.filters).length > 0) {
          const getLabel = (value) => {
            if (this.$te(`filters.labels.${value}`)) {
              return this.$t(`filters.labels.${value}`)
            } else if (value) {
              return value
            }
          }

          const formatFilter = (key, prefix, format) => {
            const prefixStr = `${this.$t(`filters.labels.${prefix}`)} `

            switch (format) {
              case 'price':
                return `${prefixStr}${toCurrency({
                  value: this.filters[key],
                  lang: this.$i18n.locale.split('_')[1],
                })}`
              case 'kms':
                return `${prefixStr}${toThousand(this.filters[key])} km`
              default:
                return this.filters[key]
            }
          }

          const activeFilters = Object.keys(this.filters)
            .flatMap((key) => {
              let filterValue

              switch (key) {
                case 'kilometresGte':
                  filterValue = formatFilter(key, 'from', 'kms')
                  break
                case 'kilometresLte':
                  filterValue = formatFilter(key, 'to', 'kms')
                  break
                case 'discountedPriceCentsGte':
                  filterValue = this.filters[key] ? formatFilter(key, 'from', 'price') : undefined
                  break
                case 'discountedPriceCentsLte':
                  filterValue = this.filters[key] ? formatFilter(key, 'to', 'price') : undefined
                  break
                case 'instalmentPriceCentsLte':
                  filterValue = this.filters[key]
                    ? this.$t('global.price_per_month', {
                        amount: formatFilter(key, 'to', 'price'),
                      })
                    : undefined
                  break
                case 'instalmentPriceCentsGte':
                  filterValue = this.filters[key]
                    ? this.$t('global.price_per_month', {
                        amount: formatFilter(key, 'from', 'price'),
                      })
                    : undefined
                  break
                case 'onOffer':
                  filterValue =
                    this.canFilterByOnOffer && this.filters[key]
                      ? this.$t('filters.labels.show_only_offers')
                      : undefined
                  break
                default:
                  if (
                    ![
                      'categoryIn',
                      'order',
                      'page',
                      'perPage',
                      'sortByRelevance',
                      'sortByFinancingPrice',
                      'useCasesIn',
                      'cubicCapacityLte',
                      'cubicCapacityGte',
                      'batteryMaxPowerLte',
                      'batteryMaxPowerGte',
                    ].includes(key)
                  ) {
                    filterValue = this.filters[key]
                  }
              }

              if (Array.isArray(filterValue)) {
                return filterValue.map((value) => ({ key: value, value: getLabel(value) }))
              } else if (filterValue !== undefined) {
                return { key, value: getLabel(filterValue) }
              }
            })
            .filter(Boolean)
            .flat()

          return activeFilters
        } else {
          return undefined
        }
      } catch (error) {
        return undefined
      }
    },
    sortingFilters() {
      return [
        {
          value: this.$t('filters.labels.relevance'),
          key: 'relevance_desc',
        },
        {
          value: this.$t('filters.labels.cheaper'),
          key: 'price_asc',
        },
        {
          value: this.$t('filters.labels.highest_price'),
          key: 'price_desc',
        },
      ]
    },
    sortingFiltersStatus() {
      if (this.filters?.sortByRelevance === true) {
        return 'relevance_desc'
      } else if (this.filters?.sortByFinancingPrice === 'asc') {
        return 'price_asc'
      } else if (this.filters?.sortByFinancingPrice === 'desc') {
        return 'price_desc'
      } else {
        return 'relevance_desc'
      }
    },
    canFilterByOnOffer() {
      return this.vehiclesOnOfferCount > 0
    },
    hiddenFilters() {
      const filters = []

      if (!this.canFilterByOnOffer) {
        filters.push('onOffer')
      }

      return filters
    },
    isFiltering() {
      let facets = false
      for (let i = 1; i <= 5; i++) {
        if (this.$route.params[`facet${i}`]) {
          facets = true
          break
        }
      }

      return facets || Object.keys(this.filters).length
    },
    canSaveAlert() {
      return !this.loading ? 'enabled' : 'disabled'
    },
    canSaveSearch() {
      return !this.loading && !this.currentSearchSaved && this.isFiltering ? 'enabled' : 'disabled'
    },
  },
  methods: {
    ...mapActions({
      saveOptions: 'searches/saveOptions',
    }),
    async afterSignActionDispatcher() {
      if (
        this.signFormType !== 'saved_searches' ||
        Object.keys(this.filters).length === 0 ||
        this.currentSearchSaved
      ) {
        return
      }
      this.loading = true

      try {
        await this.$searchesService.add({ options: this.getSearchOptions() })
        this.currentSearchSaved = true
        this.showModalsSign = false

        this.$displayNotification(
          'success',
          '',
          this.$t('messages.success.added_saved_search.message'),
          [
            {
              label: this.$t('global.check_out'),
              to: this.localePath('saved-searches'),
            },
          ],
          true,
        )

        // SEO
        this.$tracker.trackEvent('save_search_confirmation', {}, undefined)
      } catch (error) {
        this.$displayPredefinedNotification('error', 'service')
      } finally {
        this.loading = false
      }
    },
    onPerformProtectedAction(actionName) {
      if (this.loading) return

      this.signFormType = actionName

      // SEO
      switch (this.signFormType) {
        case 'saved_searches':
          this.$tracker.trackEvent('select_search', {}, undefined)
          break
      }

      if (!this.loggedIn) {
        this.showModalsSign = true
      } else {
        this.afterSignActionDispatcher()
      }
    },
    getSearchOptions() {
      const getSelectOptionText = (selectName) => {
        const selectElement = document.querySelector(`select[name=${selectName}]`)

        if (selectElement?.selectedIndex) {
          return this.filters?.[`${selectName?.replace('select-', '')}Slug`]
            ? selectElement.options[selectElement.selectedIndex]?.text
            : undefined
        }
      }

      const { page, perPage, ...remaining } = this.filters

      try {
        return {
          lang: this.lang,
          url: getFullPageUrl(this.$route),
          category: this.category,
          vehicleType: this.vehicleType,
          makeName: getSelectOptionText('select-make'),
          modelName: getSelectOptionText('select-model'),
          params: this.$route.params,
          query: this.$route.query,
          filters: remaining,
        }
      } catch {
        return {}
      }
    },
    async onNewAlert() {
      if (this.loading) return

      await this.saveOptions(this.getSearchOptions())

      // SEO
      this.$tracker.trackEvent('select_alert', {}, undefined)

      this.$router.push(this.localePath('alerts-new'))
    },
    getCardEventData(vehicle) {
      let gtm, datalayers

      if (this.isMotorbike) {
        gtm = {
          event: 'view-model-page-motorbike',
          make: vehicle?.make?.name,
          model: vehicle?.model?.name,
        }

        datalayers = {
          list_id: 'versions',
          list_name: 'version motorbike',
          item: vehicle,
          vehicleType: 'motorbike',
          category: 'new',
        }
      } else {
        switch (this.category) {
          case 'vn':
            gtm = {
              event: 'view-model-page-newcar',
              make: vehicle?.make?.name,
              model: vehicle?.name,
            }

            datalayers = {
              list_id: 'models',
              list_name: 'models new cars',
              item: vehicle,
              vehicleType: 'car',
              category: 'new',
            }
            break
          case 'km0':
            gtm = {
              event: 'view-model-page-km0',
              make: vehicle?.make?.name,
              model: vehicle?.model?.name,
            }

            datalayers = {
              list_id: 'vehicles',
              list_name: 'vehicle km0 cars',
              item: vehicle,
              vehicleType: 'car',
              category: 'km0',
            }
            break
          case 'vo':
            gtm = {
              event: 'view-model-page-vo',
              make: vehicle?.make?.name,
              model: vehicle?.model?.name,
            }

            datalayers = {
              list_id: 'vehicle',
              list_name: 'vehicle used car',
              item: vehicle,
              vehicleType: 'car',
              category: 'used',
            }
            break
          case 'move':
            gtm = {
              event: 'view-model-page-astara-selection',
              make: vehicle?.make?.name,
              model: vehicle?.model?.name,
            }

            datalayers = {
              list_id: 'vehicle',
              list_name: 'vehicle used car',
              item: vehicle,
              vehicleType: 'car',
              category: 'used',
            }
            break
        }
      }

      return {
        gtm,
        datalayers,
      }
    },
    onSubmit(newFilters, resetPagination = true, bypassLoadingCheck = false) {
      if (!bypassLoadingCheck && this.loading) return

      this.showFilters = false
      this.loading = true
      this.currentSearchSaved = false

      const query = {}

      // Set facets
      const segments = this.$route.path.split('/').filter((segment) => segment !== '')

      let basePath
      if (segments.length >= 2) {
        const firstSegmentPattern = /^[a-zA-Z]{2}_[a-zA-Z]{2}$/
        const firstSegment = segments[0]

        if (firstSegmentPattern.test(firstSegment)) {
          basePath = this.isMotorbike ? segments.slice(0, 2) : segments.slice(0, 3)
        } else {
          basePath = this.isMotorbike ? segments.slice(0, 1) : segments.slice(0, 2)
        }
      } else {
        basePath = segments
      }

      const facets = {
        facet1: undefined,
        facet2: undefined,
        facet3: undefined,
        facet4: undefined,
        facet5: undefined,
      }

      if (Object.keys(newFilters)?.length) {
        const filtersToIgnore = [
          'makeSlug',
          'modelSlug',
          'modelName',
          'categoryIn',
          'sortByRelevance',
          'sortByFinancingPrice',
        ]

        let facetsCount = 1

        if (newFilters?.makeSlug) {
          facets[`facet${facetsCount}`] = newFilters.makeSlug
          ++facetsCount

          if (newFilters?.modelSlug) {
            facets[`facet${facetsCount}`] = newFilters.modelSlug
            ++facetsCount
          }
        }

        const getActiveFilters = (object) => {
          try {
            return Object.values(object).filter((value) => value === true).length
          } catch {
            return 0
          }
        }

        const addSingleFilterToFacets = (filterKey) => {
          const count = getActiveFilters(newFilters[filterKey])

          if (count === 1) {
            filtersToIgnore.push(filterKey)
            const activeFilterKey = Object.keys(newFilters[filterKey]).find(
              (key) => newFilters[filterKey][key] === true,
            )
            facets[`facet${facetsCount}`] = this.$t(`filters.${activeFilterKey}`)
            ++facetsCount
          }
        }

        addSingleFilterToFacets('bodyworkIn')
        addSingleFilterToFacets('environmentalLabels')

        if (
          getActiveFilters(newFilters?.fuelTypeIn) === 1 &&
          getActiveFilters(newFilters?.powertrainTypeIn) === 0
        ) {
          addSingleFilterToFacets('fuelTypeIn')
        }

        if (
          getActiveFilters(newFilters?.powertrainTypeIn) === 1 &&
          getActiveFilters(newFilters?.fuelTypeIn) === 0
        ) {
          addSingleFilterToFacets('powertrainTypeIn')
        }

        // Set query params
        const translateValue = (key, value) => {
          const translate = (value) => {
            return this.$te(`filters.${value}`) ? this.$t(`filters.${value}`) : value
          }

          const isValid = (value) => {
            return (
              value !== false &&
              value !== null &&
              value !== undefined &&
              value !== 0 &&
              value !== ''
            )
          }

          if (typeof value === 'object' && value) {
            const translatedObject = []
            for (const [key, val] of Object.entries(value)) {
              if (isValid(val)) {
                translatedObject.push(translate(key))
              }
            }

            return translatedObject
          } else if (isValid(value)) {
            switch (key) {
              case 'discountedPriceCentsGte':
              case 'discountedPriceCentsLte':
              case 'instalmentPriceCentsGte':
              case 'instalmentPriceCentsLte':
                return Number(value) / 100
              case 'page':
                return Number(value)
              default:
                return translate(value)
            }
          }
        }

        Object.keys(newFilters).forEach((key) => {
          if (!filtersToIgnore.includes(key)) {
            const tValue = translateValue(key, newFilters[key])

            if (tValue) {
              query[this.$t(`filters.${key}`)] = tValue
            }
          }
        })

        // Order
        if (newFilters.sortByRelevance) {
          query.order = this.$t(`filters.relevance`)
        } else if (newFilters.sortByFinancingPrice) {
          query.order = newFilters.sortByFinancingPrice
        }
      }

      if (resetPagination || Number(query?.page) <= 1) {
        query.page = undefined
      }

      // Redirect
      const facetsPath = Object.values(facets).filter(Boolean).join('/')
      const path = `/${basePath.join('/')}/${facetsPath}`.replace(/\/$/, '')

      if (
        this.$route.path !== path ||
        JSON.stringify(this.$route.query) !== JSON.stringify(query)
      ) {
        this.$router.push({ path, query }).catch((err) => {
          this.$logger('Error during navigation', err)
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    onPaginate(page) {
      if (this.loading) return

      this.loading = true
      window.scrollTo({ top: 0 })

      const newFilters = getFitersState(this.filters)

      if (page !== newFilters.page) {
        newFilters.page = page
        this.onSubmit(newFilters, false, true)
      } else {
        this.loading = false
      }
    },
    onSortBy(order) {
      if (this.loading) return

      this.loading = true
      window.scrollTo({ top: 0 })

      const newFilters = { ...this.filters }

      switch (order) {
        case 'price_asc':
          newFilters.sortByFinancingPrice = 'asc'
          newFilters.sortByRelevance = false
          break
        case 'price_desc':
          newFilters.sortByFinancingPrice = 'desc'
          newFilters.sortByRelevance = false
          break
        case 'relevance_desc':
        default:
          newFilters.sortByFinancingPrice = undefined
          newFilters.sortByRelevance = true
          break
      }

      if (
        this.filters.sortByFinancingPrice !== newFilters.sortByFinancingPrice &&
        this.filters.sortByRelevance !== newFilters.sortByRelevance
      ) {
        this.onSubmit(getFitersState(newFilters, this.$i18n, this.$store), true, true)
      } else {
        this.loading = false
      }
    },
    onClearFilter(filterKey) {
      if (this.loading) return

      const newFilters = { ...this.filters }

      if (newFilters[filterKey] !== undefined) {
        newFilters[filterKey] = newFilters[filterKey] === true ? false : undefined
      } else {
        for (const key in newFilters) {
          if (Array.isArray(newFilters[key])) {
            newFilters[key] = newFilters[key].filter((item) => item !== filterKey)
          }
        }
      }

      this.onSubmit(getFitersState(newFilters, this.$i18n, this.$store))
    },
    onClearFilters() {
      if (this.loading) return

      this.onSubmit({})
    },
    setup() {
      this.page = this.filters.page
    },
    toggleModalSign(value) {
      this.showModalsSign = value
    },
    toggleFilters(value) {
      this.showFilters = value
    },
    toggleLoading(value) {
      this.loading = value
    },
  },
  watch: {
    vehicles: {
      handler() {
        this.loading = false
      },
      immediate: true,
    },
    filters: {
      handler() {
        this.setup()
      },
    },
  },
  created() {
    this.setup()

    try {
      this.subtitle = customSanitizer(this.seoContent?.header?.subtitle)
    } catch {
      this.subtitle = ''
    }
  },
  mounted() {
    this.randomUspCards = getRandomUspTypes(2)
  },
  beforeDestroy() {
    this.loading = true
  },
  jsonld() {
    // Data
    let itemCondition
    if (this.isVn || this.isKm0 || this.isMotorbike) {
      itemCondition = 'https://schema.org/NewCondition'
    } else {
      itemCondition = 'https://schema.org/UsedCondition'
    }

    let priceCurrency
    if (this.vehicles) {
      try {
        if (this.isMotorbike || this.isVn) {
          priceCurrency = this.vehicles[0].salesVnCheapestPrice.currency.toUpperCase()
        } else {
          priceCurrency = this.vehicles[0].salesPricing.currency.toUpperCase()
        }
      } catch {
        priceCurrency = 'EUR'
      }
    }

    const vehicles = []
    let position = 1
    this.vehicles?.forEach((vehicle) => {
      const vehicleData = normaliseData(
        vehicle,
        this.category,
        this.isMotorbike ? 'version' : this.isVn ? 'model' : 'vehicle',
      )

      let price
      try {
        price = vehicleData.pricing.financing[0].financing_price / 100
      } catch {
        price = undefined
      }

      const listItemData = {
        '@type': 'ListItem',
        position: position++,
        item: {
          '@type': 'Product',
          itemCondition,
          image: vehicleData.hero,
          url: `${getBaseUrl()}${vehicleData.url}`,
          name: [vehicleData.makeName, vehicleData.modelName, vehicleData.versionName].join(' '),
          offers: {
            '@type': 'Offer',
            price,
            priceCurrency,
            url: getFullPageUrl(this.$route),
          },
        },
      }

      if (this.isKm0) {
        listItemData.additionalType = 'km0'
      }

      vehicles.push(listItemData)
    })

    // Breadcrumbs
    const itemListElement = [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `${getBaseUrl()}${this.localePath('index')}`,
          name: this.$t('global.home'),
        },
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${getBaseUrl()}${this.isMotorbike ? 'motorbikes' : `cars-${this.category}`}`,
          name: this.$t(
            `global.categories_extended.${this.isMotorbike ? 'motorbike' : this.category}`,
          )[1],
        },
      },
    ]

    if (this.matchedMake) {
      let makeUrl
      if (this.isMotorbike) {
        makeUrl = this.localePath({
          name: 'motorbikes',
          params: { facet1: this.matchedMake[0] },
        })
      } else {
        makeUrl = this.localePath({
          name: 'car-make',
          params: { make: this.matchedMake[0] },
        })
      }
      makeUrl = `${getBaseUrl()}${makeUrl}`

      itemListElement.push({
        '@type': 'ListItem',
        position: 3,
        item: {
          '@id': makeUrl,
          name: this.matchedMake[1],
        },
      })
    }

    // Composed data
    const data = [
      {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        url: getFullPageUrl(this.$route),
        numberOfItems: 16,
        itemListElement: vehicles,
      },
      {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement,
      },
    ]

    return data
  },
  head() {
    return this.$generatePageMetas(
      this.seoContent.title,
      this.seoContent.metaTitle,
      this.seoContent.metaDescription,
    )
  },
}
</script>

<style lang="scss" scoped>
.catalog {
  position: relative;

  &__header {
    .container {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      @include size-s {
        gap: 2.5rem;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }

      @include size-m-up {
        .catalog__header__filters {
          display: none;
        }
      }
    }

    &__title {
      display: grid;
      grid-template-columns: repeat(12, [col-start] 1fr);

      .title {
        grid-column: col-start / span 12;
      }

      .subtitle {
        grid-column: col-start / span 10;
        margin: 1em 0;

        :deep(p) {
          margin: 0;

          & + p {
            margin-top: 1.4em;
          }
        }
      }

      *:not(.title, .subtitle) {
        grid-column: col-start / span 12;
      }
    }
  }

  &__main {
    padding: 2.5rem 0;
    background-color: $c-neutral-100;

    @include size-m {
      padding: 1.5rem 0;
    }

    @include size-s {
      padding: 1.25rem 0;
    }

    &__filters {
      grid-column-end: 4;
      grid-column-start: 1;
    }

    &__results {
      display: flex;
      gap: 2.5rem;
      grid-column-end: 13;
      grid-column-start: 4;
      flex-direction: column;
      padding-top: 1rem;

      @include size-xl {
        gap: 2rem;
      }

      @include size-l {
        gap: 1.5rem;
      }

      @include size-m {
        grid-column-end: 7;
        grid-column-start: 1;
      }

      @include size-s {
        gap: 1.25rem;
        grid-column-end: 5;
        grid-column-start: 1;
        padding-top: 0;
      }

      &__header {
        display: flex;
        gap: 1.625rem;
        flex-direction: column;

        @include size-m {
          gap: 1.125rem;
        }

        &__total-vehicles {
          min-height: 1.5rem;

          .total {
            color: $highlight-1;
          }
        }

        &__top {
          width: 100%;

          &__filters {
            width: 100%;

            @include size-l-up {
              display: none;
            }

            @include size-s {
              display: none;
            }
          }

          @include size-m {
            > .container-buttons,
            .filters-toggle {
              display: none !important;
            }
          }
        }

        &__active-filters {
          display: flex;
          gap: 0.75rem;
          flex-direction: column;

          .tags {
            display: flex;
            flex-wrap: wrap;
            gap: 1.25rem;
          }

          .clean-filters-button {
            color: $link-primary-enabled;
            text-decoration-line: underline;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      &__empty {
        display: flex;
        gap: 1.25rem;
        flex-direction: column;
        align-items: center;

        .clean-button {
          margin-top: 4rem;
        }

        & > p {
          text-align: center;
        }
      }

      &__legal-notice {
        display: flex;
        flex: 1;
        align-items: flex-end;
        margin: 2rem 0;
      }
    }
  }

  .alert-footer {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    @include size-s {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.25rem;

      .button {
        width: 100%;
      }
    }

    .button {
      min-width: 13.375rem;
    }

    p {
      margin: 0;
    }
  }

  .information-section .content-wrapper {
    grid-column-end: 13;
    grid-column-start: 4;

    @include size-xl {
      gap: 2rem;
    }

    @include size-l {
      gap: 1.5rem;
    }

    @include size-m {
      grid-column-end: 7;
      grid-column-start: 1;
    }

    @include size-s {
      gap: 1.25rem;
      grid-column-end: 5;
      grid-column-start: 1;
      padding-top: 0;
    }
  }
}
</style>
