const BASE_URL = '/v1'

export default ($axios) => ({
  listFaqs(lang) {
    return $axios.$get(`${BASE_URL}/faqs/${lang}`).then((faqs) => faqs)
  },

  fetchLegals(slug) {
    return $axios.$get(`${BASE_URL}/legal_pages?slug=${slug}`)
  },

  sendEmail(data, customerEmail, emailTemplate, language) {
    return $axios.$post(`${BASE_URL}/notify_email/send_email`, {
      payload: data,
      customer_email: customerEmail,
      email_template: emailTemplate,
      language,
    })
  },

  verifyCaptchaToken(token, action) {
    return $axios.$post(`${BASE_URL}/verify_recaptcha_token`, {
      token,
      expected_action: action,
    })
  },
})
