/**
 * This store tries to obtain the user country and language
 * from the URL, cookies or information provided by the browser
 * and manage the redirections to the country and language
 * distributors. It also checks validity and consistency of
 * the data (country and language obtained).
 *
 * To do so, it uses the plugin localize (/plugins/localize).
 */

import { promofinanceQuery } from '@/queries/companyQueries'

export const state = () => ({
  alive: true,
  countries: [],
  country: null,
  countryLanguages: [],
  language: null,
  languageIso: null,
  apiKey: '',
  stockStatus: false,
  promofinance: null,
})

export const getters = {
  getAlive(state) {
    return state.alive
  },
  getCountry(state) {
    return state.country
  },
  getLanguage(state) {
    return state.language
  },
  getLanguageIso(state) {
    return state.languageIso
  },
  getCountryLanguages(state) {
    return state.countryLanguages
  },
  getCountries(state) {
    return state.countries
  },
  getApolloClient(state) {
    return state.country
  },
  getApiKey(state) {
    return state.apiKey
  },
  getPromofinance(state) {
    return state.promofinance
  },
}

export const mutations = {
  setAlive(state, alive) {
    state.alive = alive
  },
  setCountry(state, country) {
    state.country = country
  },
  setLanguage(state, language) {
    state.language = language
  },
  setLanguageIso(state, languageIso) {
    state.languageIso = languageIso
  },
  setCountryLanguages(state, countryLanguages) {
    state.countryLanguages = countryLanguages
  },
  setCountries(state, countries) {
    state.countries = countries
  },
  setApiKey(state, apiKey) {
    state.apiKey = apiKey
  },
  setStockStatus(state, status) {
    state.stockStatus = status
  },
  setPromofinance(state, promofinance) {
    state.promofinance = promofinance
  },
}

export const actions = {
  async nuxtServerInit({ commit, dispatch, state }, { app, $config, route, redirect, env, query }) {
    // Init user
    const token = app.$cookiez.get('token')
    if (token) {
      await dispatch('auth/refreshToken', { token })
    }

    // Set available countries
    commit('setCountries', $config.countries)
    commit('mailing/setServer', env.config.host)
    try {
      const client = app.apolloProvider.defaultClient
      const promofinance = await client
        .query({ query: promofinanceQuery })
        .then(({ data }) => data.promofinance)
      commit('setPromofinance', promofinance)
    } catch (error) {
      commit('setPromofinance', {
        active: false,
        maximumVehicleYears: 6,
        minimalPriceToFinancingCents: 1050000,
      })
    }
    // Init i18n
    if (!app.i18n.locale) {
      app.i18n.setLocale(app.$cookiez.get('preferred_language') || 'es_ES')
    }

    // Try to get local configuration (country and language)
    let country = app.$localize.getValidCountry(state.countries)

    if (country) {
      await app.$localize.setValidCountry(country)

      const language = app.$localize.getValidLanguage(country.iso, state.countryLanguages)

      if (language) {
        app.$localize.setValidLanguage(language)
        // No necessary redirect
        /* if (route.path === '/') {
          redirect(app.localePath('index'))
        } */
      } else {
        // Temporal: while there is only one country
        app.$localize.setValidLanguage('es_ES')
        // No necessary redirect
        // redirect(app.localePath('index'))
      }
      // } else {
      //   redirect(`/${country}/select-language`)
      // }
      // No necessary redirect
      /* } else if (state.countries.length > 1) {
      // Temporal: while there is only one country
      redirect('/select-country') */
    } else {
      // Temporal: while there is only one country
      country = state.countries.es
      await app.$localize.setValidCountry(country)

      // No necessary redirect
      /* if (state.countryLanguages.length > 1) {
        redirect(`/${country.iso}/select-language`)
      } else {
        app.$localize.setValidLanguage(state.countryLanguages[0].code)
        redirect(app.localePath('index'))
      } */
      app.$localize.setValidLanguage(state.countryLanguages[0].code)
    }
    // } else {
    //   redirect('/select-country')
    // }
  },
  async nuxtClientInit({ commit, state }) {
    // Init stores
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  setStockStatus({ commit }, params) {
    commit('setStockStatus', params)
  },
}
