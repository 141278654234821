const BASE_URL = '/v1'

export default ($axios) => ({
  /**
   * Get the payment methods available to this component.
   */
  listPaymentMethods() {
    return $axios.$get(`${BASE_URL}/payment_methods`).then((response) => response.payment_methods)
  },

  /**
   * Get the exchange rates in company.
   */
  getCompany() {
    return $axios.$get(`${BASE_URL}/company`).then((response) => response.company)
  },

  getOrders() {
    return $axios.$get(`${BASE_URL}/orders`)
  },

  /**
   * Create a new order attempt based on a vehicle config that will be purchased.
   * @param {string} vehicleConfigSlug the slug of the vehicle config to associate the order to
   */
  createOrder(vehicleConfigSlug) {
    return $axios.$post(`${BASE_URL}/orders`, { vehicle_config_slug: vehicleConfigSlug })
  },

  /**
   * Get the information for the order given its ID (such as the vehicle config information).
   * @param {string} uid UID of the order to get the data from
   */
  async getOrder(uid) {
    const order = await $axios.$get(`${BASE_URL}/orders/${uid}`)
    if (order.preferred && order.preferred.dealer) {
      return {
        config: order.vehicleConfigDetails,
        payments: order.payments,
        dealer: order.preferred.dealer,
      }
    } else {
      return {
        config: order.vehicleConfigDetails,
        payments: order.payments,
      }
    }
  },

  /**
   * Init a new financing payment based in a payment method, and associated to an order.
   * @param {string} orderUID UID of the order that will own the payment
   */
  createFinancingPayment(orderUID) {
    return $axios.$post(`${BASE_URL}/orders/${orderUID}/payments`, {
      payment_method: 'FinancingPaymentMethod',
      payment_details: { financial_entity_uid: process.env.defaultFinancialEntity },
    })
  },

  /**
   * Init a new bank transfer payment based in a payment method, and associated to an order.
   * @param {string} orderUID UID of the order that will own the payment
   */
  createBankTransferPayment(orderUID) {
    return $axios.$post(`${BASE_URL}/orders/${orderUID}/payments`, {
      payment_method: 'BankTransferPaymentMethod',
    })
  },

  /**
   * Validate financing_details into associated transaction to prescoring and send email.
   * @param {string} uid UID of the financing transaction
   */
  prescoringFinancingTransaction(uid, data = true) {
    const payload = data ? {} : { SendToNemuru: false }
    return $axios.$post(`${BASE_URL}/transaction_financings/${uid}/prescorings`, payload)
  },

  /**
   * Add details (such as customer information) to the financing purchase information.
   * @param {string} uid UID of the financing transaction
   * @param {object} data the payload to associate to the financing transaction
   */
  updateDetailsFinancingTransaction(uid, data) {
    return $axios.$post(`${BASE_URL}/transaction_financings/${uid}/details`, {
      financing_details: data,
    })
  },

  /**
   * Get details (such as customer information) to the financing purchase information.
   * @param {string} uid UID of the financing transaction
   */
  getDetailsFinancingTransaction(uid) {
    return $axios.$get(`${BASE_URL}/transaction_financings/${uid}/details`)
  },

  /**
   * Add tariff to financing transaction.
   * @param {string} uid UID of the financing transaction
   * @param {object} data the payload to associate the tariff to financing transaction
   */
  async updateFinancingTariff(uid, data) {
    return await $axios.$post(`${BASE_URL}/transaction_financings/${uid}/tariff`, data)
  },
  /**
   * Get tariff from financing transaction.
   * @param {string} uid UID of the financing attempt to associate the data to
   */
  async getTariff(uid) {
    return await $axios.$get(`${BASE_URL}/transaction_financings/${uid}/tariff`)
  },

  /**
   * get vehicle config info
   */
  async getVehicleConfig(configSlug) {
    return await $axios.$get(`${BASE_URL}/vehicle_configs/${configSlug}`)
  },

  /**
   * BBVA calculate
   */
  async calculate(options) {
    try {
      const data = {
        data: {
          ...options,
        },
      }
      return await $axios.$post(`${BASE_URL}/financing/calc`, data)
    } catch (error) {
      return null
    }
  },

  async sendAutologinUrl(uid) {
    return await $axios.$post(`${BASE_URL}/financing/orders/${uid}/send_autologin_url`)
  },
})
