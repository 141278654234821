export default ($axios) => ({
  fetchCalendar(params) {
    return $axios.$get('/v1/test_drive/calendar', { params })
  },
  createBooking(body) {
    return $axios.$post('/v1/test_drive/bookings', body)
  },
  requestBooking(body) {
    return $axios.$post('/v1/test_drive/requests', body)
  },
})
