<template>
  <PartialsFlag
    :class="`use-case-flag--style-${type.replaceAll('_', '-')}`"
    :data-cy="`${type.replaceAll('_', '-')}-flag`"
    :filled-icon="icon.filled"
    :icon="icon.name"
    :label="$t(`use_cases_flags.${type}`)"
  />
</template>

<script>
// Components
import PartialsFlag from '@/components/partials/Flag.vue'

// Other
const TYPES = [
  'black_friday',
  'on_offer',
  'out_of_stock',
  'showroomprive',
  'sold_out',
  'summer_days',
]

export default {
  name: 'PartialUseCaseFlag',
  components: {
    PartialsFlag,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => TYPES.includes(value),
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'sold_out':
          return { name: 'label_off', filled: false }
        case 'out_of_stock':
          return { name: 'remove_shopping_cart', filled: false }
        case 'black_friday':
        case 'summer_days':
          return { name: 'sell', filled: false }
        case 'on_offer':
          return { name: 'verified', filled: false }
        case 'showroomprive':
          return { name: 'lens', filled: true }
      }

      return undefined
    },
  },
}
</script>

<style lang="scss" scoped>
.use-case-flag--style-sold-out {
  --color: #fff;
  --icon-color: #fff;
  --background-color: #41366c;
}

.use-case-flag--style-black-friday {
  --color: #fab42d;
  --icon-color: #fab42d;
  --background-color: #1e1932;
}

.use-case-flag--style-out-of-stock,
.use-case-flag--style-summer-days {
  --color: #1e1932;
  --icon-color: #1e1932;
  --background-color: #fab42d;
}

.use-case-flag--style-on-offer {
  --color: #fff;
  --icon-color: #fff;
  --background-color: #ff5a00;
}

.use-case-flag--style-showroomprive {
  --color: #fff;
  --icon-color: #fff;
  --background-color: #f2554f;
}
</style>
