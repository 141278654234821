<template>
  <div
    id="mainNavbar"
    :class="[
      'navbar',
      {
        'navbar--attatched': attatched,
        'navbar--landing-mode': landingMode,
        'navbar--open': open,
        'navbar--purchase-mode': purchaseMode,
        'navbar--scrolled': scrolled,
        'navbar--show-up': animationStatus >= 2,
      },
    ]"
  >
    <div class="navbar__overlay" :class="{ open }" @click="() => onCloseMenu()" />

    <!-- Top bar -->
    <div class="navbar__top s-pt-20">
      <div class="container container-flex s-gap-16 container-flex--justify-space-between">
        <!-- Logo & Home page link -->
        <div class="navbar__top__right">
          <NuxtLink
            :aria-label="$t('accesibility.goto_home')"
            :to="localePath('index')"
            class="logo"
          >
            <Icon name="astara_logo" />
          </NuxtLink>
        </div>

        <div class="navbar__top__left container-flex s-gap-16">
          <!-- Contact info -->
          <div class="container-flex s-gap-8 f-size-14">
            <span>{{ $t('navbar.we_help_you') }}</span>

            <a
              :href="`tel:${contactPhone}`"
              class="f-not-as-link container-flex container-flex--inline s-gap-4"
              @click.capture="() => $tracker.phoneClick(contactPhone)"
            >
              <Icon name="wifi_calling_3" size="1rem" />
              <span>{{ contactPhone }}</span>
            </a>
          </div>

          <!-- Customer -->
          <div class="container-flex s-gap-8">
            <!-- Language switcher -->
            <LangSwitcherPartial v-if="isSizeXLUp" class="lang-switcher" />

            <!-- Customer: favourites & saved searches & login -->
            <template v-if="!landingMode && isSizeXLUp">
              <template v-if="loggedIn">
                <Button
                  type-theme="ghost-dark"
                  size="small"
                  class="ghost-button ghost-button--action"
                  :to="localePath('favourites')"
                  icon="favorite_border"
                />

                <Button
                  type-theme="ghost-dark"
                  size="small"
                  class="ghost-button ghost-button--action"
                  :to="localePath('saved-searches')"
                  icon="bookmark_border"
                />

                <UserSubmenuPartial @logout="() => onLogout()" />
              </template>
              <Button
                v-else
                filled
                type-theme="ghost-dark"
                size="medium"
                icon="person"
                class="psuedo-link ghost-button ghost-button--action signin"
                @click="() => onPseudoRedirect('sign-in', false)"
              />
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom bar -->
    <nav class="navbar__bottom">
      <div
        ref="bottomNavbarContainer"
        class="container container-flex s-gap-16 container-flex--justify-space-between"
      >
        <!-- Navigation bar -->
        <div class="navbar__bottom__left">
          <!-- Purchase back button -->
          <NuxtLink v-if="purchaseMode" class="purchase-back-link f-not-as-link" :to="goBack">
            <Icon name="navigate_before" size="1.5rem" />
            <span>{{ $t('navbar.back_to_payment_methods') }}</span>
          </NuxtLink>

          <!-- Logo & Home page link -->
          <NuxtLink
            v-else-if="!isSizeXLUp"
            :aria-label="$t('accesibility.goto_home')"
            :to="localePath('index')"
            class="logo"
          >
            <Icon name="astara_logo" />
          </NuxtLink>

          <ul v-show="!purchaseMode" ref="bottomNavbarContainerLinksList" class="size-14">
            <!-- Home page -->
            <li
              v-show="(scrolled && animationStatus === 2) || animationStatus === 3"
              class="home-link"
            >
              <NuxtLink class="f-not-as-link" :to="localePath('index')">
                <Icon name="home" filled size="1.5rem" />
              </NuxtLink>
            </li>

            <!-- Visible links -->
            <li v-for="link in visibleLinks?.length ? visibleLinks : links" :key="link.key">
              <NuxtLink
                v-if="!link.obfuscated"
                :data-cy="`navbar-link-${link.key}`"
                :prefetch="link?.prefetch"
                :to="link.url"
                class="f-not-as-link"
                @click.native="() => onClickOnMenuEntry(link)"
              >
                <span>{{ link.name }}</span>
              </NuxtLink>
              <div
                v-else
                :data-cy="`navbar-link-${link.key}`"
                class="pseudo-link f-not-as-link"
                @click="() => onClickOnMenuPseudoEntry(link)"
              >
                <span>{{ link.name }}</span>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="!purchaseMode || (purchaseMode && !isSizeLUp)" class="navbar__bottom__right">
          <!-- Customer -->
          <div
            v-show="(scrolled && animationStatus === 2) || animationStatus === 3"
            class="container-flex s-gap-8"
          >
            <!-- Language switcher -->
            <LangSwitcherPartial v-if="isSizeXLUp" class="lang-switcher" />

            <!-- Customer: favourites & saved searches & login -->
            <template v-if="!landingMode && isSizeXLUp">
              <template v-if="loggedIn">
                <Button
                  type-theme="ghost-dark"
                  size="small"
                  class="ghost-button ghost-button--action"
                  :to="localePath('favourites')"
                  icon="favorite_border"
                />

                <Button
                  type-theme="ghost-dark"
                  size="small"
                  class="ghost-button ghost-button--action"
                  :to="localePath('saved-searches')"
                  icon="bookmark_border"
                />

                <UserSubmenuPartial @logout="() => onLogout()" />
              </template>
              <Button
                v-else
                filled
                type-theme="ghost-dark"
                size="medium"
                icon="person"
                class="pseudo-link ghost-button ghost-button--action signin"
                @click="() => onPseudoRedirect('sign-in', false)"
              />
            </template>
          </div>

          <!-- Aside menu button -->
          <div
            class="menu-button s-ml-8 s-mr-8"
            :class="{ open }"
            @click="() => onToggleMenuButton()"
          >
            <div class="icon">
              <span :class="{ animated: menuButtonAnimated }" />
              <span />
              <span :class="{ animated: menuButtonAnimated }" />
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- Aside menu -->
    <aside class="navbar__menu" :class="{ open }">
      <nav class="navbar__menu__content">
        <div
          v-show="(isSizeLUp && animationStatus >= 2) || !isSizeLUp || !loggedIn"
          class="navbar__menu__content__list navbar__menu__content__list--top"
        >
          <!-- Contact info -->
          <div
            v-show="(isSizeLUp && animationStatus >= 2) || !isSizeLUp"
            class="contact container-flex s-gap-8 f-size-14"
          >
            <span>{{ $t('navbar.we_help_you') }}</span>

            <a
              :href="`tel:${contactPhone}`"
              class="f-not-as-link container-flex container-flex--inline s-gap-4"
              @click.capture="() => $tracker.phoneClick(contactPhone)"
            >
              <Icon name="wifi_calling_3" size="1rem" />
              <span>{{ contactPhone }}</span>
            </a>
          </div>

          <!-- Customer: favourites and saved searches -->
          <div v-if="!landingMode && loggedIn" class="navbar__menu__content__list__customer">
            <Button
              type-theme="ghost-dark"
              size="small"
              class="ghost-button"
              :to="localePath('favourites')"
              icon="favorite_border"
            >
              <span>
                {{ $t('navbar.favourites') }}
              </span>
            </Button>

            <Button
              type-theme="ghost-dark"
              size="small"
              class="ghost-button"
              :to="localePath('saved-searches')"
              icon="bookmark_border"
            >
              <span>
                {{ $t('navbar.saved_searches') }}
              </span>
            </Button>
          </div>

          <!-- Customer: session -->
          <div v-else>
            <Button
              v-if="!isSizeXLUp"
              filled
              type-theme="ghost-dark"
              size="small"
              class="pseudo-link ghost-button signin s-mb-24"
              @click="() => onPseudoRedirect('sign-in')"
            >
              <Icon name="login" size="1.5rem" />
              <span>
                {{ $t('navbar.login') }}
              </span>
            </Button>

            <div
              class="pseudo-link navbar__menu__content__list__item f-not-as-link"
              @click="() => onPseudoRedirect('sign-up')"
            >
              <span>
                {{ $t('navbar.signup') }}
              </span>
              <Icon name="arrow_forward" size="1.5rem" />
            </div>
          </div>
        </div>

        <!-- Customer: account -->
        <div v-if="loggedIn && !purchaseMode" class="navbar__menu__content__list">
          <span class="navbar__menu__content__list__title">
            {{ $t('navbar.account') }}
          </span>

          <NuxtLink
            class="navbar__menu__content__list__item f-not-as-link"
            :to="localePath('customer-orders')"
            @click.native="() => onCloseMenu()"
          >
            <span>
              {{ $t('navbar.orders') }}
            </span>
            <Icon name="directions_car" size="1.5rem" />
          </NuxtLink>
          <NuxtLink
            class="navbar__menu__content__list__item f-not-as-link"
            :to="localePath('customer-details')"
            @click.native="() => onCloseMenu()"
          >
            <span>
              {{ $t('navbar.personal_data') }}
            </span>
            <Icon filled name="person" size="1.5rem" />
          </NuxtLink>
          <NuxtLink
            class="navbar__menu__content__list__item f-not-as-link"
            :to="localePath('customer-settings')"
            @click.native="() => onCloseMenu()"
          >
            <span>
              {{ $t('navbar.settings') }}
            </span>
            <Icon name="settings" size="1.5rem" />
          </NuxtLink>
        </div>

        <!-- Hidden links -->
        <template v-if="!purchaseMode">
          <div v-for="group in groupedLinks" :key="group.key" class="navbar__menu__content__list">
            <span class="navbar__menu__content__list__title">
              {{ $t(`navbar.${group.key}`) }}
            </span>

            <template v-for="link in group.links">
              <NuxtLink
                v-if="!link.obfuscated"
                :key="link.key"
                :data-cy="`navbar-link-${link.key}`"
                :prefetch="link?.prefetch"
                :to="link.url"
                class="navbar__menu__content__list__item f-not-as-link"
                @click.native="() => onClickOnMenuEntry(link)"
              >
                <span>
                  {{ link.name }}
                </span>
                <Icon name="arrow_forward" size="1.5rem" />
              </NuxtLink>
              <div
                v-else
                :key="`${link.key}_obfuscated`"
                :data-cy="`navbar-link-${link.key}`"
                class="pseudo-link navbar__menu__content__list__item f-not-as-link"
                @click="() => onClickOnMenuPseudoEntry(link)"
              >
                <span>{{ link.name }}</span>
              </div>
            </template>
          </div>
        </template>

        <!-- Footer -->
        <div
          v-if="!isSizeXLUp"
          class="navbar__menu__content__list navbar__menu__content__list--bottom"
        >
          <!-- Language switcher -->
          <LangSwitcherPartial class="navbar__lang-switcher" />

          <!-- Customer: logout -->
          <div>
            <Button
              v-if="loggedIn"
              type-theme="ghost-dark"
              size="small"
              class="ghost-button logout"
              @click="() => onLogout()"
            >
              <Icon name="logout" size="1.5rem" />
              <span>
                {{ $t('navbar.logout') }}
              </span>
            </Button>
          </div>
        </div>
      </nav>
    </aside>

    <!-- Global notifications -->
    <Notifications />
  </div>
</template>

<script>
// Dependencies
import { mapGetters, mapActions } from 'vuex'

// Helpers & mixins
import { validateBlogUrl } from '@/helpers/urls'
import checkViewportSize from '@/mixins/checkViewportSize'

// Components
import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'
import UserSubmenuPartial from '@/components/partials/UserSubmenu.vue'
import LangSwitcherPartial from '@/components/partials/LangSwitcher.vue'
import Notifications from '@/components/Notifications.vue'

export default {
  components: {
    Icon,
    Button,
    UserSubmenuPartial,
    LangSwitcherPartial,
    Notifications,
  },
  props: {
    attatched: {
      type: Boolean,
      required: false,
      default: false,
    },
    purchaseMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    landingMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    customEdge: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      animationStatus: 0,
      contactPhone: process.env.contactPhone,
      hiddenLinks: [],
      isTicking: false,
      lastScroll: 0,
      links: [],
      menuButtonAnimated: false,
      open: false,
      scrolled: false,
      visibleLinks: [],
    }
  },
  mixins: [checkViewportSize],
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
    }),
    goBack() {
      return this.localePath({
        name: 'uid-payment-choose',
        params: { uid: this.$route.params.uid },
      })
    },
    groupedLinks() {
      const groups = {}

      this.hiddenLinks.forEach((item) => {
        const groupKey = item.group || 'explore'

        if (!groups[groupKey]) {
          groups[groupKey] = {
            key: groupKey,
            links: [],
          }
        }

        groups[groupKey].links.push(item)
      })

      return Object.values(groups).filter((group) => group.links.length > 0)
    },
  },
  methods: {
    ...mapActions({
      onLogout: 'auth/logout',
    }),
    onClickOnMenuEntry(link) {
      if (link?.event) {
        this.pushEvent(link.event?.name, link.event?.category)
      }

      this.onCloseMenu()
    },
    onClickOnMenuPseudoEntry(link) {
      this.onClickOnMenuEntry()
      this.$router.push(this.localePath(link.url))
    },
    onToggleMenuButton() {
      this.menuButtonAnimated = true
      this.open = !this.open

      if (window?.innerWidth < 1280) {
        document.body.style.overflow = this.open ? 'hidden' : ''
      } else {
        document.body.style.overflow = ''
      }

      if (this.open && this.attatched) document.body.style.position = 'fixed'
      if (!this.open && this.attatched) document.body.style.position = ''
    },
    onCloseMenu() {
      this.open = false
      document.body.style.overflow = ''
    },
    onPseudoRedirect(path, closeMenu = true) {
      if (closeMenu) {
        this.onCloseMenu()
      }

      this.$router.push(this.localePath(path))
    },
    onScroll() {
      if (this.isTicking) return

      this.isTicking = true

      requestAnimationFrame(() => {
        const currentScroll = window.scrollY

        this.scrolled = currentScroll > 0

        try {
          if (window.innerWidth >= 1280) {
            if (!this.$refs?.bottomNavbarContainer) {
              throw new Error('bottomNavbarContainer is not defined')
            }

            const edge =
              this.customEdge || Math.min(window.innerHeight, window.innerWidth <= 1439 ? 768 : 900)

            if (currentScroll > this.lastScroll) {
              if (currentScroll >= edge) {
                document.body.classList.add('navbar-scrolled')

                if (this.animationStatus < 1) {
                  this.$refs.bottomNavbarContainer.style.opacity = 0

                  setTimeout(() => {
                    this.animationStatus = 2
                    this.distributeLinks()
                    this.$refs.bottomNavbarContainer.style.opacity = 1
                  }, 500)
                }
              }
            } else if (currentScroll <= edge) {
              if (this.animationStatus > 1) {
                this.$refs.bottomNavbarContainer.style.opacity = 0
                this.animationStatus = 3

                setTimeout(() => {
                  this.animationStatus = 0
                  this.distributeLinks()
                  this.$refs.bottomNavbarContainer.style.opacity = 1
                }, 500)
              }

              document.body.classList.remove('navbar-scrolled')
            }

            this.lastScroll = currentScroll
          }
        } catch (err) {
          this.$logger('Error in Navbar onScroll fn', err)

          this.isTicking = false
          return
        }

        this.isTicking = false
      })
    },
    pushEvent(name, category) {
      if (category) {
        this.$tracker.menuClick(category)
      }

      if (process?.browser && process?.env?.gtm_enabled && name) {
        this.$gtm.push({ event: name })
      }
    },
    distributeLinks() {
      try {
        const container = this.$refs?.bottomNavbarContainerLinksList

        if (container && window.innerWidth >= 1280) {
          const availableWidth = container.clientWidth
          const marginOfSafety = 50 // Safety margin to avoid overlap
          const tempLinks = [...this.links]
          let totalWidth = 0
          this.visibleLinks = []
          this.hiddenLinks = []

          const getLinkWidth = (linkText) => {
            const tempSpan = document.createElement('span')
            tempSpan.style.visibility = 'hidden'
            tempSpan.style.whiteSpace = 'nowrap'
            tempSpan.style.position = 'absolute'
            tempSpan.style.top = '-9999px'
            tempSpan.innerText = linkText
            document.body.appendChild(tempSpan)
            const width = tempSpan.offsetWidth
            document.body.removeChild(tempSpan)
            return width + 16
          }

          for (let i = 0; i < tempLinks.length; i++) {
            const link = tempLinks[i]

            const linkWidth = getLinkWidth(link.name)

            if (link.hidden || totalWidth + linkWidth + marginOfSafety > availableWidth) {
              this.hiddenLinks = tempLinks.slice(i)
              break
            } else {
              this.visibleLinks.push(link)
              totalWidth += linkWidth
            }
          }
        } else {
          this.visibleLinks = []
          this.hiddenLinks = this.links
        }
      } catch {
        this.visibleLinks = []
        this.hiddenLinks = this.links
      }
    },
  },
  watch: {
    loggedIn: {
      handler() {
        this.distributeLinks()
      },
      deep: true,
    },
  },
  created() {
    this.links = []

    let isSlowConnection
    try {
      const connection =
        navigator.connection || navigator.mozConnection || navigator.webkitConnection

      if (connection) {
        const slowTypes = ['slow-2g', '2g', '3g']
        isSlowConnection =
          slowTypes.includes(connection.effectiveType) || connection.saveData === true
      }

      isSlowConnection = false
    } catch {
      isSlowConnection = true
    }

    if (this.$isBlackFriday()) {
      this.links.push({
        key: 'black_friday',
        name: this.$t('navbar.black_friday'),
        url: this.localePath('blackfriday'),
        event: { name: undefined, category: 'catalog black friday' },
      })
    }

    this.links.push(
      {
        key: 'astara_selection',
        name: this.$t('navbar.astara_selection'),
        url: this.localePath('astara-selection'),
        event: { name: 'view_astara_selection_page', category: 'catalog move vehicles' },
        obfuscated: true,
      },
      {
        key: 'motos',
        name: this.$t('navbar.motos'),
        url: this.localePath('motorbikes'),
        event: { name: 'view_motorbike_page', category: 'catalog motorbikes' },
        prefetch: false && !isSlowConnection,
      },
      {
        key: 'vo_cars',
        name: this.$t('navbar.vo_cars'),
        url: this.localePath('cars-vo'),
        event: { name: 'view_vo_page', category: 'catalog used vehicles' },
        prefetch: false && !isSlowConnection,
      },
      {
        key: 'km0_cars',
        name: this.$t('navbar.km0_cars'),
        url: this.localePath('cars-km0'),
        event: { name: 'view_km0_page', category: 'catalog km0 vehicles' },
        prefetch: false && !isSlowConnection,
      },
      {
        key: 'vn_cars',
        name: this.$t('navbar.vn_cars'),
        url: this.localePath('cars-vn'),
        event: { name: 'view_newcar_page', category: 'catalog new vehicles' },
        prefetch: false && !isSlowConnection,
      },
      {
        key: 'financing',
        name: this.$t('navbar.financing'),
        url: this.localePath('financing'),
        prefetch: false && !isSlowConnection,
      },
      {
        key: 'trade_in',
        name: this.$t('navbar.trade_in'),
        url: this.localePath('trade-in'),
        prefetch: false && !isSlowConnection,
      },
      {
        key: 'warranty',
        name: this.$t('navbar.warranty'),
        url: this.localePath('warranty'),
        obfuscated: false,
      },
      {
        key: 'eco_vehicles',
        name: this.$t('navbar.eco_vehicles'),
        url: this.localePath('vehiculos-eco'),
        event: { name: 'view_ecovehicles_page', category: 'catalog eco vehicles' },
        obfuscated: false,
      },
    )

    if (validateBlogUrl(this.$route)) {
      this.links.push({
        key: 'blog',
        name: this.$t('navbar.blog'),
        url: this.localePath('news'),
        event: { name: 'view-blog-page', category: 'blog' },
      })
    }

    this.links.push(
      {
        key: 'about',
        name: this.$t('navbar.about'),
        url: this.localePath('about'),
        group: 'more_info',
        hidden: true,
        obfuscated: true,
      },
      {
        key: 'faqs',
        name: this.$t('navbar.faqs'),
        url: this.localePath('faqs'),
        group: 'more_info',
        hidden: true,
        obfuscated: true,
      },
    )
  },
  mounted() {
    this.$nextTick(() => {
      this.distributeLinks()

      // Add event listeners
      window.addEventListener('scroll', this.onScroll)
      window.addEventListener('resize', this.distributeLinks)
    })
  },
  beforeDestroy() {
    // Remove event listeners
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.distributeLinks)

    // Restore document
    document.body.style.overflow = ''

    if (this.attatched) {
      document.body.style.position = ''
    }
  },
}
</script>

<style lang="scss">
:root {
  --navbar-height: 7.25rem;

  @include size-l {
    --navbar-height: 3.75rem;
  }

  body.navbar-scrolled {
    --navbar-height: 3.75rem;
  }
}
</style>

<style lang="scss" scoped>
a,
.pseudo-link {
  color: $c-white;
  font-weight: 500 !important;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer !important;

  span {
    color: $c-white;
  }

  > * {
    pointer-events: none;
  }
}

.ghost-button {
  width: auto;
  border: 0 !important;
  background-color: transparent !important;
  color: $c-support-01-500 !important;

  > * {
    pointer-events: none;
  }

  .icon,
  span {
    color: $c-support-01-500 !important;
  }

  &:hover,
  &:active {
    color: $c-support-01-100 !important;

    .icon {
      color: inherit !important;
    }

    span {
      color: inherit !important;
    }
  }

  &:hover {
    background-color: $c-neutral-900 !important;
  }

  &:active {
    background-color: $c-neutral-800 !important;
  }

  &--action {
    aspect-ratio: 1 / 1 !important;
    padding: 0.5rem !important;
  }
}

.logo {
  display: flex;
  align-items: center;

  .icon {
    width: 12rem !important;
    height: 2.25rem !important;
  }
}

.navbar__top .lang-switcher,
.navbar__bottom .lang-switcher {
  padding: 0;

  :deep(.submenu__header .icon) {
    display: none;
  }
}

.navbar__top {
  position: relative;
  z-index: 999999;
  height: 3.5rem;

  .contact a {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;

    span {
      text-decoration: underline;
    }
  }

  @include size-l {
    display: none;
  }
}

.navbar__bottom {
  display: flex;
  position: relative;
  z-index: 999998;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 3.75rem;

  .container {
    transition: opacity 0.5s ease-in-out;
  }

  @include size-l {
    height: 3.75rem;
  }

  &__left,
  &__right {
    display: flex;
    z-index: 111112;
    align-items: center;
    height: 100%;
  }

  &__left {
    flex: 1 1 0;
    min-width: 0;
    gap: 1.25rem;

    ul,
    ul li,
    ul li a,
    ul li .pseudo-link {
      height: 100%;
    }

    ul {
      display: flex;
      gap: 1rem;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      white-space: nowrap;

      @include size-l {
        display: none;
      }

      li a,
      li .pseudo-link {
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.125rem;
          transition: background-color 0.3s ease-in-out;
        }

        &:hover::before {
          background-color: $c-primary-100;
        }

        &:active::before {
          background-color: $c-primary-050;
        }
      }
    }

    li a,
    li .pseudo-link,
    .purchase-back-link {
      display: inline-flex;
      position: relative;
      align-items: center;
      justify-content: center;
    }
  }

  &__right {
    flex: 0 1 auto;
    justify-content: flex-end;
  }
}

.navbar__overlay {
  visibility: hidden;
  position: fixed;
  top: 3.75rem;
  right: 0;
  left: 0;
  width: 100vw;
  height: calc(100% + 7.25rem);
  transition: opacity 0.75s ease-in-out, visibility 1s ease-in-out, top 0.3s ease-in-out;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  will-change: opacity;

  @include size-xl-up {
    top: 7.25rem;
  }

  &.open {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
  }
}

.navbar__menu {
  --width: calc(50% - 14.75rem);

  @include size-xl {
    --width: 32.5%;
  }

  @include size-l {
    --width: 40.75%;
  }

  @include size-m {
    --width: 50%;
  }

  @include size-s {
    --width: 100%;
  }

  @include size-xl-up {
    padding-right: 0.5rem;
  }

  /* stylelint-disable-next-line */
  & {
    visibility: hidden;
    position: fixed;
    z-index: 111110;
    top: 0;
    right: 0;
    width: var(--width);
    height: 100%;
    padding-top: 3.75rem;
    overflow: hidden;
    transform: translateX(100%) translateZ(0);
    transition: all 0.75s ease;
    background-color: $navbar-background-color;
    will-change: transform;
  }

  @include size-xl-up {
    padding-top: 7.25rem;
  }

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  &.open {
    visibility: visible;
    position: fixed;
    transform: translateX(0%) translateZ(0);
  }

  &__content {
    position: relative;
    height: 100%;
    overflow-y: auto;
    background-image: url('/images/a-astara-corner.svg');
    background-repeat: no-repeat;
    background-position: right bottom;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0.625rem;
      box-shadow: inset 0 0 0.3125rem $navbar-background-color;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.625rem;
      background: $c-white;
    }

    &__list {
      --padding-right: 2.75rem;

      @include size-xl {
        --padding-right: 2.5rem;
      }

      @include size-l {
        --padding-right: 2rem;
      }

      @include size-m {
        --padding-right: 1.5rem;
      }

      @include size-s {
        --padding-right: 1.5rem;
      }

      @include size-xs {
        --padding-right: 1.25rem;
      }

      /* stylelint-disable-next-line */
      & {
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        padding: 1.5rem;
        padding-right: var(--padding-right);
      }

      @include size-xxl-up {
        padding-right: calc(50vw - 42.75rem);
      }

      &:not(.navbar__menu__content__list--top) + .navbar__menu__content__list {
        border-top: 0.0625rem solid $c-primary-600;
      }

      &--top {
        border-bottom: 0.0625rem solid $c-primary-600;
      }

      :deep(.submenu) {
        padding-right: 0;
        padding-left: 0;
      }

      &__title {
        font-size: 1.5rem;
        font-weight: 500;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        padding: 0.325rem 0;
        text-transform: uppercase;

        @include size-l-up {
          &:hover span {
            color: #fcfcfc;
            text-decoration: underline;
          }

          &:active span {
            color: #f4f4f4;
            text-decoration: underline;
          }
        }
      }

      &__customer {
        display: flex;
        position: sticky;
        z-index: 111112;
        top: 0;
        flex-direction: row;
        justify-content: space-between;

        .logout {
          transform: translateX(0.375rem);
        }

        @include size-xl-up {
          display: none;
        }
      }

      &--bottom .logout {
        transform: translateX(-0.375rem);
      }

      .signin {
        transform: translateX(-0.375rem) !important;
      }
    }
  }
}

.menu-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 2.25rem;
  padding: 0.5rem 0;
  cursor: pointer;

  > * {
    pointer-events: none;
  }

  .icon {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 1.5rem;
    gap: 0.3125rem;

    span {
      display: block;
      width: 100%;
      height: 0.125rem;
      border-radius: 1.125rem;
      background: $c-neutral-050;

      &:nth-child(1).animated {
        animation-name: top-line-off;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }

      &:nth-child(3).animated {
        animation-name: bottom-line-off;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }
    }
  }

  &.open .icon span {
    &:nth-child(1) {
      animation-name: top-line-on;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }

    &:nth-child(2) {
      transition: background-color 0.3s ease-in-out;
      background-color: transparent;
    }

    &:nth-child(3) {
      animation-name: bottom-line-on;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }

    // Animations
    @keyframes top-line-off {
      0% {
        transform: translateY(0.5rem) rotate(305deg);
      }
      50% {
        transform: translateY(0.5rem) rotate(0deg);
      }
      100% {
        transform: translateY(0) rotate(0deg);
      }
    }

    @keyframes bottom-line-off {
      0% {
        transform: translateY(-0.5rem) rotate(405deg);
      }
      50% {
        transform: translateY(-0.5rem) rotate(0deg);
      }
      100% {
        transform: translateY(0) rotate(0deg);
      }
    }

    @keyframes top-line-on {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(0.5rem);
      }
      100% {
        transform: translateY(0.5rem) rotate(225deg);
      }
    }

    @keyframes bottom-line-on {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-0.5rem);
      }
      100% {
        transform: translateY(-0.375rem) rotate(315deg);
      }
    }
  }
}

.navbar {
  z-index: 100;
  max-width: 100vw;
  height: 3.75rem;
  transition: top 0.3s ease-in-out;
  background-color: $navbar-background-color;
  color: $c-white;
  will-change: top;

  @include size-xl-up {
    height: 7.25rem;
  }

  &:not(.navbar--attatched) {
    position: sticky;
    top: 0;
  }

  a,
  .pseudo-link {
    color: $c-white;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    span {
      color: $c-white;
    }
  }

  &--scrolled {
    .navbar__bottom {
      width: 100%;
      height: 3.75rem;
    }

    &.navbar--show-up {
      top: -3.5rem;

      .navbar__overlay {
        top: 3.75rem;
      }

      .navbar__menu {
        padding-top: 3.75rem;
      }
    }
  }
}
</style>
