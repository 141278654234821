export const state = () => ({
  campaignData: {},
})

export const mutations = {
  setCampaignData(state, campaignData) {
    state.campaignData = { ...state.campaignData, ...campaignData }

    if (process.client && sessionStorage) {
      const storedState = sessionStorage.getItem('astara_store')

      let currentState
      if (storedState) {
        currentState = JSON.parse(storedState)
      }

      sessionStorage.setItem(
        'astara_store',
        JSON.stringify({
          ...currentState,
          campaignData: state.campaignData,
        }),
      )
    }
  },
}

export const actions = {
  initializeStore({ state, commit }) {
    if (process.client && sessionStorage) {
      const storedState = sessionStorage.getItem('astara_store')
      let currentState

      if (storedState) {
        currentState = JSON.parse(storedState)
      }

      if (!Object.keys(state?.campaignData || {}).length) {
        if (currentState && currentState.campaignData) {
          commit('setCampaignData', currentState.campaignData)
        }
      } else {
        const newState = {
          ...currentState,
          campaignData: state.campaignData,
        }
        sessionStorage.setItem('astara_store', JSON.stringify(newState))
      }
    }
  },

  extractQueryParams({ commit }, query) {
    const campaignData = {}
    let foundParams = false

    Object.keys(query).forEach((key) => {
      if (key.startsWith('utm_')) {
        const newKey = key.slice(4)
        campaignData[newKey] = query[key]
        foundParams = true
      } else if (key === 'test_campaign') {
        campaignData.testing = query[key]
        foundParams = true
      }
    })

    if (foundParams) {
      commit('setCampaignData', campaignData)
    }
  },
}

export const getters = {
  getcampaignData: (state) => state.campaignData,
  getSource: (state) => state.campaignData.source || null,
  getMedium: (state) => state.campaignData.medium || null,
  getCampaign: (state) => state.campaignData.campaign || null,
  getContent: (state) => state.campaignData.content || null,
  getTerm: (state) => state.campaignData.term || null,
  getAction: (state) => state.campaignData.action || null,
  getTesting: (state) => state.campaignData.testing || null,
}
